import * as actions from '../actions/types';

const initialState = {};

const posReducer = (state = initialState, action) => {
  switch (action.type) {

    case actions.POS_LIST:
      return {
        ...state,
        posList: action.posList,
      };

    case actions.POS_ONBOARDING_LIST:
      return {
        ...state,
        posOnboardingList: action.posOnboardingList,
      };


    case actions.POS_DETAIL:
      return {
        ...state,
        posDetail: action.posDetail,
      };

    case actions.POLICY_SOLD_LIST:
      return {
        ...state,
        policySoldList: action.policySoldList,
      };

    case actions.POSP_EARNING:
      return {
        ...state,
        pospEarning: action.pospEarning,
      };

    default:
      return state;
  }
};

export default posReducer;

