/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
// core components
import GridItem from '../../components/Grid/GridItem'
import GridContainer from '../../components/Grid/GridContainer'
import PosTable from './PosTable'
import Card from '../../components/Card/Card'
import CardHeader from '../../components/Card/CardHeader'
import CardBody from '../../components/Card/CardBody'
import Search from '@material-ui/icons/Search'
import CustomInput from '../../components/CustomInput/CustomInput'
import Button from '../../components/CustomButtons/Button'
import styleHeader from '../../assets/jss/componentsStyles/headerLinksStyle'
import { isEmpty, exportCsv, getStatus } from '../../utils/common'
import ImportExportIcon from '@material-ui/icons/ImportExport'
import FuzzySearch from 'fuzzy-search';
import Tooltip from '@material-ui/core/Tooltip';
import EcoIcon from '@material-ui/icons/Eco';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';


const styles = {
  cardCategoryWhite: {
    '&,& a,& a:hover,& a:focus': {
      color: 'rgba(255,255,255,.62)',
      margin: '0',
      fontSize: '14px',
      marginTop: '0',
      marginBottom: '0'
    },
    '& a,& a:hover,& a:focus': {
      color: '#FFFFFF'
    }
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: 'Montserrat',
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: '#777',
      fontSize: '65%',
      fontWeight: '400',
      lineHeight: '1'
    }
  },
  inputSearchType: {
    color: 'white',
    padding: '0'
  },
  paddingStyle: {
    padding: '0 12px 0'
  }
}
function createData(fullName, phone, pincode, panNo, approvalStatus, _id) {
  return { fullName, phone, pincode, panNo, approvalStatus, _id }
}

const useStyles = makeStyles(styles)
const headerStyle = makeStyles(styleHeader)

export default function PosList(props) {
  const classes = useStyles()
  const headerClass = headerStyle()
  const { posList = [], getDetails, uploadFile } = props
  const rows = []
  // const detailButton =  (<Button color="primary">Details</Button>)
  posList.forEach(pos => {
    let panNo = pos.panNo || '';
    // const {personalInfo = {}} = pos;
    const { personalInfo: { documentsUploaded } = [] } = pos;
    // if (!isEmpty(documentsUploaded)) {
    //   const doc = documentsUploaded.find(val => val.documentSubType === 'aadhar_front');
    // }
    const status = getStatus(pos);
    const row = createData(pos.fullName, pos.phone, pos.pinCode, panNo, status, pos._id)
    rows.push(row)
  })



  const [filteredList, setList] = React.useState()

  const [showInput, toggleInput] = React.useState(false)

  React.useEffect(() => {
    setList(rows) // api.data.count from api after update store
  }, [posList])
  // let newList = [...rows];

  React.useEffect(() => { document.getElementById('searchInput') && document.getElementById('searchInput').focus() }, [showInput])

  const filterList = (e) => {
    let currentList = []
    let newList = []

    // If the search bar isn't empty
    if (e.target.value !== '') {
      // Assign the original list to currentList
      currentList = rows
      const searcher = new FuzzySearch(currentList, ['fullName', 'phone', 'pincode', 'panNo', 'approvalStatus', '_id'], {
        caseSensitive: false
      })
      newList = searcher.search(e.target.value.toLowerCase())
    } else {
      // If the search bar is empty, set newList to original task list
      newList = [...rows]
    }

    setList(newList)
  }

  const exportPosp = (event) => {
    event.preventDefault()

    const data = []
    posList.filter(pos => !isEmpty(pos.fullName)).forEach(pos => {
      let dataToShow = []
      const {
        bankInfo = [],
        businessInfo = {},
        certification = {},
        educationInfo = { } = {},
        personalInfo = [],
        email = '',
        fullName = '',
        phone = '',
        pinCode = '',
        roleCode = '',
        partnerCode=''
      } = pos

      dataToShow.push({'Name': fullName ||''});
      dataToShow.push({'Phone No': phone ||''});
      dataToShow.push({'Email': email ||''});
      dataToShow.push({'Pincode': pinCode ||''});
      dataToShow.push({'Partner Code': partnerCode ||''});
      dataToShow.push({'Role Code': roleCode || ''});



      if (bankInfo && bankInfo.isComplete) {
        dataToShow.push({ 'Account Holder Name': bankInfo.accountHolderName ||'' })
        dataToShow.push({ 'Bank Account Number': bankInfo.bankAccountNo ||'' })
        dataToShow.push({ 'IFSC Code': bankInfo.ifscCode ||'' })
      }

      if(certification && certification.isComplete){
        const {isCertified, isExamPassed, isMaterialDownloaded} = certification;
        dataToShow.push({'Is Certified': isCertified? 'Yes': 'No'})
        dataToShow.push({'Is Exam Passed': isExamPassed? 'Yes': 'No'})
        dataToShow.push({'Is Material Downloaded': isMaterialDownloaded? 'Yes': 'No'})}

      if (businessInfo && businessInfo.isComplete) {
        const { contactsAndRefs = {}, experience = {},
          interestedIncentives = {}, licensesAvailable = {},
          localLanguage = {}, monthlyAvgBusiness = {}, preferredCommissionCycle = '',
          sourceOfIncome = '' } = businessInfo;

          dataToShow.push({ 'Preferred Commission Cycle': preferredCommissionCycle ||'' });
          dataToShow.push({ 'Source Of Income': sourceOfIncome ||'' });

        [contactsAndRefs, experience,
          interestedIncentives, licensesAvailable,
          localLanguage, monthlyAvgBusiness].forEach(objs => {
            if (!isEmpty(objs)) {
              Object.keys(objs).filter(key => key !== '_id').forEach(key => dataToShow.push({ [key]: objs[key] ||'' }));
            }
          });
      }

//      personalInfo.forEach(objs => {
//        if (!isEmpty(objs)) {
//          Object.keys(objs).filter(key => (key !== '_id' && key !=='documentsUploaded')).forEach(key => dataToShow.push({ [key]: objs[key] ||'' }));
//          Array.isArray(objs.documentsUploaded) && objs['documentsUploaded'].forEach(documentsUploaded => {Object.keys(documentsUploaded).filter(key => (key !== '_id')).forEach(key => dataToShow.push({ [key]: documentsUploaded[key] ||'' }));
//          })
//        }
//      });

      data.push(Object.assign({}, ...dataToShow))
    })
    const param = {
      fileName: 'POSP_LIST',
      title: 'POSP List',
      data: data
    }
    return exportCsv(param)
  }

  const exportSampleUploadCsv = (event) => {
    event.preventDefault();
    const param = {
      fileName: 'POSP_UPLOAD',
      title: 'POSP List',
      data: [{ broker_id: '', phone_no: '', pan_no: '' }]
    };
    return exportCsv(param);
  }


  const headCells = [
    { id: 'fullName', label: 'Name' },
    { id: 'phone', label: 'Phone No' },
    { id: 'pinCode', label: 'Pincode' },
    { id: 'panNo', label: 'PAN Number' },
    { id: 'approvalStatus', label: 'Status' },
    { id: '_id', label: 'Details' }]

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <span style={{ float: 'left' }}>
              <h4 className={classes.cardTitleWhite}>POSP List</h4>

            </span>
            <span className={headerClass.searchWrapper} style={{ float: 'right' }}>
              {showInput && <CustomInput
                id="searchInput"
                formControlProps={{
                  className: classNames(headerClass.margin, headerClass.search)
                }}
                inputProps={{
                  inputProps: {
                    'aria-label': 'Search',
                    className: classes.inputSearchType
                  }
                }}
                onChange={(event) => filterList(event)}
              />}
              <Button className={classes.paddingStyle} color="white" aria-label="edit" simple onClick={() => toggleInput(!showInput)}>
                <Search /> {' '}Search
              </Button>

              <Button color="white" className={classes.paddingStyle} aria-label="edit" simple onClick={(event) => exportPosp(event)}>
                <ImportExportIcon /> {' '} Export
              </Button>
              <Tooltip title="Download Sample file to upload Posp List">
                <Button color="white" className={classes.paddingStyle} aria-label="edit" simple onClick={(event) => exportSampleUploadCsv(event)}>
                  <EcoIcon /> {' '} Download Sample
              </Button>
              </Tooltip>

              <Tooltip title="Upload Posp List">
                <Button color="white" className={classes.paddingStyle} aria-label="edit" simple component="label" variant="contained">
                  <CloudUploadIcon /> {' '} Upload List
                <input style={{ display: 'none' }} type="file" id='file' name="file" onChange={uploadFile} />
                </Button>
              </Tooltip>
            </span>

          </CardHeader>
          <CardBody>

            <PosTable
              tableHeaderColor="primary"
              headCells={headCells}
              isSelectRequired={false}
              orderByParameter="name"
              orderAs="asc"
              rows={filteredList}
              getDetails={getDetails}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  )
}
