/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styles from "../../assets/jss/componentsStyles/tableStyle.js";
import { makeStyles } from '@material-ui/core/styles';
import Button from "../../components/CustomButtons/Button";
import { ONBOARD_STATUS } from "../../utils/constants";
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import Tooltip from '@material-ui/core/Tooltip';

import {
    TableBody,
    TableSortLabel,
    Checkbox,
    TableCell,
    TableRow,
    TableHead,
    Table,
} from '@material-ui/core';


function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

//headCell -{id:"", label: ''}
function EnhancedTableHead(props) {
    const { classes, tableHeaderColor, headCells, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, isSelectRequired } = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };
    return (
        <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
            <TableRow className={classes.tableHeadRow}>
                {isSelectRequired && <TableCell padding="checkbox">
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'select all' }}
                    />
                </TableCell>}
                {headCells.map((headCell, key) => {
                    return (
                        <TableCell
                            className={classes.tableCell + " " + classes.tableHeadCell}
                            sortDirection={orderBy === headCell.id ? order : false}
                            key={key}
                        >

                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={order}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {/* {orderBy === headCell.id ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </span>
                                ) : null} */}
                            </TableSortLabel>
                        </TableCell>
                    );
                })}

            </TableRow>
        </TableHead>

    );
}
/*
@props( rows, orderAs, orderByParameter, isSelectRequired, tableHeaderColor, headCells, getDetails)
*/

const useStyles = makeStyles(styles);
export default function PosOnboardingTable(props) {

    const { rows = [], orderAs = "", orderByParameter = "",
        isSelectRequired = false, tableHeaderColor = "", headCells = [], getDetails = {},
         tabName = "", handleClose } = props;
    const classes = useStyles();
    const [order, setOrder] = React.useState(orderAs);
    const [orderBy, setOrderBy] = React.useState(orderByParameter);
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    // const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);


    const handleRequestSort = (event, property) => {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const isSelected = name => selected.indexOf(name) !== -1;

    const handleSelectAllClick = event => {
        if (event.target.checked) {
            const newSelecteds = rows.map(n => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    return (
        <Fragment>
            <div className={classes.tableResponsive}>
                <Table className={classes.table} stickyHeader>

                    <EnhancedTableHead
                        classes={classes}
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={rows.length}
                        isSelectRequired={isSelectRequired}
                        tableHeaderColor={tableHeaderColor}
                        headCells={headCells}
                    />

                    <TableBody>
                        {stableSort(rows, getSorting(order, orderBy))
                            .filter(row => row.approval === tabName)
                            .map((row, index) => {
                                const isItemSelected = isSelected(row.name);
                                const labelId = `enhanced-table-checkbox-${index}`;
                                return (
                                    <TableRow
                                        hover
                                        onClick={event => handleClick(event, row.name)}
                                        role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={row.name}
                                        selected={isItemSelected}
                                        className={classes.tableBodyRow}
                                    >
                                        {isSelectRequired && <TableCell padding="checkbox">
                                            <Checkbox
                                                checked={isItemSelected}
                                                inputProps={{ 'aria-labelledby': labelId }}
                                            />
                                        </TableCell>}

                                        {Object.keys(row).map((key, index) => {
                                            let display = null;
                                            switch (key) {
                                                case '_id':
                                                    // display = (<Button color="primary" round size="sm" id={row[key]} onClick={(event) => getDetails(event, row[key])}>Details</Button>);
                                                    break;
                                                case 'fullName':
                                                    // display = (<Button color="primary" round size="sm" id={row[key]} onClick={(event) => getDetails(event, row[key])}>Details</Button>);
                                                    // eslint-disable-next-line no-script-url
                                                    display = (<a href="javascript:void(0)" style= {{textTransform: 'capitalize'}} id={row[key]} onClick={(event) => getDetails(event, row._id)}>{row[key]}</a>)
                                                    break;
                                                case 'isExamPassed':
                                                    display = row.isExamPassed ? 'PASS' : 'FAIL';
                                                    break;

                                                case 'approval':
                                                    switch (tabName) {
                                                        case ONBOARD_STATUS.ONBOARDED:
                                                            display = (<div>
                                                                <Tooltip title="Reject">
                                                                    <Button color="rose" justIcon round size="sm" id={row[key]}
                                                                        onClick={(event) => handleClose(event, { posId: row._id, status: false })}><ClearIcon /></Button>
                                                                </Tooltip>
                                                            </div>);
                                                            break;
                                                        case ONBOARD_STATUS.REJECTED:
                                                            display = (<div>
                                                                <Tooltip title="Onboard">
                                                                    <Button color="success" raised round justIcon size="sm" id={row[key]} style={{ marginRight: "5px" }}
                                                                        onClick={(event) => handleClose(event, { posId: row._id, status: true })}><CheckIcon /></Button>
                                                                </Tooltip>
                                                            </div>);
                                                            break;
                                                        default:
                                                            display = (<div>
                                                                <Tooltip title="Onboard">
                                                                    <Button color="success" raised round justIcon size="sm" id={row[key]} style={{ marginRight: "5px" }}
                                                                        onClick={(event) => handleClose(event, { posId: row._id, status: true })}><CheckIcon /></Button>
                                                                </Tooltip>
                                                                <Tooltip title="Reject">
                                                                    <Button color="rose" justIcon round size="sm" id={row[key]}
                                                                        onClick={(event) => handleClose(event, { posId: row._id, status: false })}><ClearIcon /></Button>
                                                                </Tooltip>
                                                            </div>)
                                                            break;

                                                    }


                                                    break;

                                                default:
                                                    display = (row[key]);
                                                    break;
                                            }
                                            return (
                                                <TableCell className={classes.tableCell} key={index}>
                                                    {display}
                                                </TableCell>
                                            );
                                        })}
                                        {/* <TableCell className={classes.tableCell} key={}>
                <Button color="primary" onClick={(event) => getDetails(event)}>Details</Button>
                </TableCell> */}

                                    </TableRow>
                                );
                            })}

                    </TableBody>
                </Table>
            </div>
            {/* <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          'aria-label': 'previous page',
        }}
        nextIconButtonProps={{
          'aria-label': 'next page',
        }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      /> */}
        </Fragment>
    );
}

PosOnboardingTable.propTypes = {
    rows: PropTypes.array.isRequired,
    orderAs: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderByParameter: PropTypes.string.isRequired,
    isSelectRequired: PropTypes.bool.isRequired,
    tableHeaderColor: PropTypes.string.isRequired,
    headCells: PropTypes.array.isRequired
}