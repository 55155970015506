import { combineReducers } from 'redux'
import { routerReducer } from "react-router-redux";
import loginReducer from './loginReducer'
import posReducer from './posReducer'
import posCommissionReducer from './posCommissionReducer'
import dsaReducer from './dsaReducer'
import productReducer from './productReducer'
import commonReducer from './commonReducer'
import payoutReducer from './payoutReducer';
import payoutFrequencyReducer from './payoutFrequencyReducer';
import specialUserReducer from './specialUserReducer';

export const rootReducers = combineReducers({
  routing: routerReducer,
  loginReducer,
  posReducer,
  posCommissionReducer,
  dsaReducer,
  productReducer,
  commonReducer,
  payoutReducer,
  payoutFrequencyReducer,
  specialUserReducer,
  });