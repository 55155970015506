import React, {useState} from "react";
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import CustomInput from 'components/CustomInput/CustomInput';
import CustomDatePicker from 'components/DatePicker/CustomDatePicker';
import SelectOption from '../../../components/CustomSelect/SelectOption';
import { isEmpty } from 'utils/common';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import moment from 'moment';
import Typography from "@material-ui/core/Typography";
import Card from '../../../components/Card/Card';
import CardHeader from '../../../components/Card/CardHeader';
import CardBody from '../../../components/Card/CardBody';
import Button from 'components/CustomButtons/Button';
import { makeStyles } from '@material-ui/core/styles';
import Img from "react-image";
import defaultImage from '../../../assets/img/image_placeholder.jpg'




const styles = {
    sectionTitle: {
      fontSize: "1.25rem",
      fontFamily: "Montserrat",
      fontWeight: "500",
      lineHeight: "1.6",
      textTransform: "uppercase"
    },
    buttonCenter: {
      padding: "25px",
      textAlign: "center"
    },
    customFileUpload: {
      border: "1px solid #ccc",
      display: "inline-block",
      padding: "6px 12px",
      cursor: "pointer",
      boxShadow: "0 2px 2px 0 rgba(156, 39, 176, 0.14), 0 3px 1px -2px rgba(156, 39, 176, 0.2), 0 1px 5px 0 rgba(156, 39, 176, 0.12)",
      backgroundColor: "#9c27b0",
      borderRadius: "30px",
      border: "none",
      cursor: "pointer",
      padding: "12px 30px",
      position: "relative",
      fontSize: "12px",
      minWidth: "auto",
      minHeight: "auto",
      textAlign: "center",
      transition: "box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1)",
      fontWeight: "400",
      lineHeight: "1.42857143",
      whiteSpace: "nowrap",
      willChange: "box-shadow, transform",
      textTransform: "uppercase",
      verticalAlign: "middle",
      color: "white",
      fontFamily: "Montserrat",
      width: "200px"
    },
    error: {
        fontSize: '11px',
        color: 'red',
        float: 'left'
    },
    img: {
        maxHeight: '100%',
        width: '15%',
        height: 'auto',
        marginRight: 'auto',
        marginLeft: 'auto',
        display: 'block',
        maxWidth: '100%'
    },
  }
  

const useStyles = makeStyles(styles);


export default function SpecialForm(props) {
  const themeClass = useStyles();

    const { classes, updateKeyValueInState=()=>{}, updateKeyValueInObjectState=()=>{}, updateKeyValueInStateForRole=()=>{}, isError=()=>{},
        validateInput=()=>{}, formErrors, maritalStatusList=[], userRolesList=[], isEdit=true,
        fullName, role=()=>{}, dob, phone, email, gender, educationInfo, personalInfo,
        maritalStatus, alternatePhone, aadharNo, panNo, searchUsersList, bqpUsersList,
        addressLine1, addressLine2, addressLine3, searchBQPParams, searchUsersParams,referState,
        state, city, district, area, pinCode, country, selectedBQP, selectedRefUser,
        landmark, fromUpdate=false, ifsc, bankInfo, linkedBQP={}, downloadUploadedFile,rotUsersList,
        referredBy={}, fileUploadHandler, getUploadedFile, selectedPAN, selectedAadhaarFront, selectedAadhaarBack, selectedCertificate, selectedCheque
    } = props;
   
    const [roleOptions, setRoleOptions] = React.useState([]);
    const [selectedRole, setSelectedRole] = React.useState("");
    const [selectPAN, setSelectedPAN] = React.useState("");
    const [selectAadhaarFront, setSelectedAadhaarFront] = React.useState("");
    const [selectAadhaarBack, setSelectedAadhaarBack] = React.useState("");
    const [selectCertificate, setSelectedCertificate] = React.useState("");
    const [selectCheque, setSelectedCheque] = React.useState("");
    const [bqp, setSelectedBqp] = React.useState('');
    const [refer, setSelectedReferredBy] = React.useState('');
    const [bqpOptions, allBQPOptions] = React.useState([]);
    const [usersOptions, allUsersList] = React.useState([]);
    const [rotUsersOptions, setROTUser] = React.useState([]);


    React.useEffect(() => {
        if(!isEmpty(linkedBQP)){
            const user = linkedBQP.phone+' - '+linkedBQP.fullName;
            setSelectedBqp(user);
        }
    },[props]);

    React.useEffect(() => {
        if(!isEmpty(referredBy)){
            const user = referredBy.phone?referredBy.phone+' - '+referredBy.fullName:'';
            setSelectedReferredBy(user);
        }
    },[referredBy]);

    React.useEffect(() => {
        if(!isEmpty(selectedPAN)){
            setSelectedPAN(selectedPAN);
        }
    },[props]);
    React.useEffect(() => {
        if(!isEmpty(selectedAadhaarFront)){
            setSelectedAadhaarFront(selectedAadhaarFront);
        }
    },[props]);
    React.useEffect(() => {
        if(!isEmpty(selectedAadhaarBack)){
            setSelectedAadhaarBack(selectedAadhaarBack);
        }
    },[props]);
    React.useEffect(() => {
        if(!isEmpty(selectedCertificate)){
            setSelectedCertificate(selectedCertificate);
        }
    },[props]);
    React.useEffect(() => {
        if(!isEmpty(selectedCheque)){
            setSelectedCheque(selectedCheque);
        }
    },[props]);

    React.useEffect(() => {
        if(!isEmpty(role)){
            setSelectedRole(role.role);
        }
    }, [role]);

    let userListOptions = [];
    let disabledRole = false;
    if(selectedRole && (selectedRole === 'BQP' || selectedRole === 'SP')){
        disabledRole = true;
    }
    let referredByText = 'Referred By';
    let updateReferText = 'Update refer by';
    if(selectedRole && (selectedRole==='POSP')){
        referredByText = 'Recruited By';
        updateReferText = 'Update recruited by';
    }

    React.useEffect(() => {
        if (!isEmpty(userRolesList)) {
        const tempList = [];
        userRolesList.forEach(userRole => {
            // if(disabledRole || (userRole.role !== "BQP" && userRole.role !== "SP")){
            tempList.push({ value: userRole.role, label: userRole.role })
            // }
        });
        setRoleOptions(tempList);
        }
    }, [props, selectedRole])

    React.useEffect(() => {
        if (!isEmpty(bqpUsersList)) {
        const tempList = [];
        bqpUsersList.forEach(user => tempList.push(
            { id: user._id, label: user.phone +' - '+ user.fullName}));
            allBQPOptions(tempList);
        }
    }, [bqpUsersList])

    React.useEffect(() => {
        if (!isEmpty(searchUsersList)) {
        const tempList = [];
        searchUsersList.forEach(user => tempList.push(
            { id: user._id, label: user.phone +' - '+ user.fullName}));
            allUsersList(tempList);
        }
    }, [searchUsersList])

    React.useEffect(() => {
        if (!isEmpty(rotUsersList)) {
        const tempList = [];
        rotUsersList.forEach(user => tempList.push(
            { id: user._id, label: user.phone +' - '+ user.fullName}));
            setROTUser(tempList);
        }
    }, [rotUsersList])

    if(selectedRole && (selectedRole==='POSP')){
        userListOptions = [];
        userListOptions = rotUsersOptions;
    }else{
        userListOptions = usersOptions;
    }

    const genderOptions = [{ value: 'MALE', label: 'MALE' },
        { value: 'FEMALE', label: 'FEMALE' },
        { value: 'OTHERS', label: 'OTHERS' }];

    const getMaritalStatusOptions = () => {
        const martialStatusOptions = [{ value: 'SINGLE', label: 'SINGLE' },
        { value: 'MARRIED', label: 'MARRIED' },
        { value: 'OTHERS', label: 'OTHERS' }];
        return martialStatusOptions
    }
    const highestQualification = [{ value: 'High School', label: 'High School' },
        { value: 'Secondary School', label: 'Secondary School' },
        { value: 'Graduate', label: 'Graduate' },
        { value: 'Post Graduate', label: 'Post Graduate' },
        { value: 'None of the above', label: 'None of the above' }];

    let yearOptions = [];
    const getYears = () => {
        if(dob != 0){
            let years = moment().format('YYYY');
            let today = moment(dob).format('YYYY');
            let startyear = parseInt(today) + 14;
            for(let year=parseInt(years); year>startyear; year--){
                yearOptions.push({label: year, value: year});
            }
        }else{
            let today = moment().format('YYYY');
            let startyear = parseInt(today) - 100;
            for(let year=parseInt(today); year>startyear; year--){
                yearOptions.push({label: year, value: year});
            }
        }
    }
    getYears();

    const [educationInformation, setEducationInfo] = React.useState({});
    const [education, setEducation] = React.useState({});
    React.useEffect(() => {
        if(!isEmpty(educationInfo)){
            setEducationInfo(educationInfo);
            setEducation(educationInfo.documentsUploaded[0]);
        }
    }, [props]);
    
    const [bankInformation, setBankInfo] = React.useState({});
    const [bank, setBank] = React.useState({});
    React.useEffect(() => {
        if(!isEmpty(bankInfo)){
            setBankInfo(bankInfo);
            setBank(bankInfo.documentsUploaded[0]);
        }
    }, [props]);

    const [pan, setPan] = React.useState({});
    const [aadharFront, setAadharFront] = React.useState({});
    const [aadharBack, setAadharBack] = React.useState({});

    React.useEffect(() => {
        if(personalInfo){
            personalInfo.documentsUploaded.forEach((obj)=>{
                if(obj.documentSubType=="pan"){
                    setPan(obj)
                }else if(obj.documentSubType=="aadhaarFront"){
                    setAadharFront(obj)
                }else if(obj.documentSubType=="aadhaarBack"){
                    setAadharBack(obj)
                }
            })
        }
    }, [props]);

    const [bqpOption, setBQP] = React.useState({});
    React.useEffect(() => {
        if(!isEmpty(linkedBQP)){
            const user = {'value': linkedBQP.phone+' - '+linkedBQP.fullName}
            setBQP(user);
        }
    }, [linkedBQP]);

    let today = new Date();
    const maxDob =  new Date(today.getFullYear()-18, today.getMonth(), today.getDate());
    const minDob = new Date(today.getFullYear()-70, today.getMonth(), today.getDate());

    return (
        <GridContainer>
            <GridItem xs={12} sm={6}>
                <SelectOption
                    id="role"
                    name="role"
                    labelText="Role"
                    selectOptions={roleOptions}
                    error={isError('role')}
                    formControlProps={{
                        fullWidth: true,
                        disabled: !isEdit || disabledRole
                    }}
                    isRequired
                    onSelectChange={({ target: { value }}) => (value ? updateKeyValueInStateForRole('role', value) : updateKeyValueInStateForRole('role', null))}
                    inputProps={{
                        value: selectedRole || '',
                        onBlur: ({ target: { value } }) => validateInput('role', value),
                        error: isError('role'),
                    }}
                />
                {isError('role') ? (
                    <span className={themeClass.error}>
                        {formErrors['role']}
                    </span>
                ) : null}
            </GridItem>

            <GridItem xs={12} sm={6}>
                <CustomInput
                    labelText="Name"
                    id="fullName"
                    formControlProps={{
                        fullWidth: true
                    }}
                    isRequired
                    error={isError('fullName')}
                    onChange={({ target: { value } }) => (value ? updateKeyValueInState('fullName', value) : updateKeyValueInState('fullName', null))}
                    inputProps={{
                        value: fullName || "",
                        onBlur: ({ target: { value } }) => validateInput('fullName', value),
                        error: isError('fullName'),
                        disabled: !isEdit

                    }}
                />
                {isError('fullName') ? (
                    <span className={themeClass.error}>
                        {formErrors['fullName']}
                    </span>
                ) : null}
            </GridItem>

            <GridItem xs={12} sm={6}>
                <CustomDatePicker
                    id="dob"
                    label="Date of Birth"
                    error={isError('dob')}
                    inputProps={{
                        format: "dd/MM/yyyy",
                        disabled: !isEdit,
                        maxDate: maxDob,
                        minDate: minDob,
                    }}
                    rest={{
                        onBlur: (event) => validateInput('dob', event),
                        error: isError('dob'),
                        onAccept: (event) => validateInput('dob', event)
                      }}
                    value={dob || null}
                    onChange={(event) => (event ? updateKeyValueInState('dob', event) : updateKeyValueInState('dob', null))}
                />
                {isError('dob') ? (
                    <span className={themeClass.error}>
                        {formErrors['dob']}
                    </span>
                ) : null}
            </GridItem>

            <GridItem xs={12} sm={6}>
                <CustomInput
                    labelText="Phone Number"
                    id="phone"
                    formControlProps={{
                        fullWidth: true
                    }}
                    isRequired
                    error={isError('phone')}
                    onChange={({ target: { value } }) => (value ? updateKeyValueInState('phone', value) : updateKeyValueInState('phone', null))}
                    inputProps={{
                        value: phone || "",
                        onBlur: ({ target: { value } }) => validateInput('phone', value),
                        error: isError('phone'),
                        type: 'number',
                        disabled: fromUpdate

                    }}
                />
                {/* {isError('phone') ? (
                    <span className={themeClass.error}>
                        {formErrors['phone']}
                    </span>
                ) : null} */}
            </GridItem>

            <GridItem xs={12} sm={6}>
                <CustomInput
                    labelText="Email Id"
                    id="email"
                    formControlProps={{
                        fullWidth: true
                    }}
                    error={isError('email')}
                    onChange={({ target: { value } }) => (value ? updateKeyValueInState('email', value) : updateKeyValueInState('email', null))}
                    inputProps={{
                        value: email || "",
                        onBlur: ({ target: { value } }) => validateInput('email', value),
                        error: isError('email'),
                        disabled: !isEdit
                    }}
                />
                {isError('email') ? (
                    <span className={themeClass.error}>
                        {formErrors['email']}
                    </span>
                ) : null}
            </GridItem>

            <GridItem xs={12} sm={6}>
                <SelectOption
                    id="gender"
                    labelText="Gender"
                    selectOptions={genderOptions}
                    error={isError('gender')}
                    formControlProps={{
                        fullWidth: true
                    }}
                    onSelectChange={({ target: { value } }) => (value ? updateKeyValueInState('gender', value) : updateKeyValueInState('gender', null))}
                    inputProps={{
                        value: gender || '',
                        onBlur: ({ target: { value } }) => validateInput('gender', value),
                        error: isError('gender'),
                        disabled: !isEdit
                    }}
                />
                {isError('gender') ? (
                    <span className={themeClass.error}>
                        {formErrors['gender']}
                    </span>
                ) : null}
            </GridItem>

            <GridItem xs={12} sm={6}>
                <SelectOption
                    id="maritalStatus"
                    labelText="Marital Status "
                    selectOptions={getMaritalStatusOptions()}
                    error={isError('maritalStatus')}
                    formControlProps={{
                        fullWidth: true
                    }}
                    onSelectChange={({ target: { value } }) => (value ? updateKeyValueInState('maritalStatus', value) : updateKeyValueInState('maritalStatus', null))}
                    inputProps={{

                        value: maritalStatus || '',
                        onBlur: ({ target: { value } }) => validateInput('maritalStatus', value),
                        error: isError('maritalStatus'),
                        disabled: !isEdit
                    }}
                />
                {isError('maritalStatus') ? (
                    <span className={themeClass.error}>
                        {formErrors['maritalStatus']}
                    </span>
                ) : null}
            </GridItem>

            <GridItem xs={12} sm={6}>
                <CustomInput
                    labelText="Alternate Phone Number"
                    id="alternatePhone"
                    formControlProps={{
                        fullWidth: true
                    }}
                    error={isError('alternatePhone')}
                    onChange={({ target: { value } }) => (value ? updateKeyValueInState('alternatePhone', value) : updateKeyValueInState('alternatePhone', null))}
                    inputProps={{
                        value: alternatePhone || "",
                        onBlur: ({ target: { value } }) => validateInput('alternatePhone', value),
                        error: isError('alternatePhone'),
                        type: 'number',
                        disabled: !isEdit

                    }}
                />
                {isError('alternatePhone') ? (
                    <span className={themeClass.error}>
                        {formErrors['alternatePhone']}
                    </span>
                ) : null}
            </GridItem>

            <GridItem xs={12} sm={6}>
                <CustomInput
                    labelText="Aadhaar Number"
                    id="aadharNo"
                    formControlProps={{
                        fullWidth: true
                    }}
                    error={isError('aadharNo')}
                    onChange={({ target: { value } }) => (value ? updateKeyValueInState('aadharNo', value) : updateKeyValueInState('aadharNo', null))}
                    inputProps={{
                        value: aadharNo || "",
                        onBlur: ({ target: { value } }) => validateInput('aadharNo', value),
                        error: isError('aadharNo'),
                        disabled: !isEdit,
                        type: "number"

                    }}
                />
                {isError('aadharNo') ? (
                    <span className={themeClass.error}>
                        {formErrors['aadharNo']}
                    </span>
                ) : null}
            </GridItem>

            <GridItem xs={12} sm={6}>
                <CustomInput
                    labelText="Pan Number"
                    id="panNo"
                    formControlProps={{
                        fullWidth: true
                    }}
                    error={isError('panNo')}
                    onChange={({ target: { value } }) => (value ? updateKeyValueInState('panNo', value) : updateKeyValueInState('panNo', null))}
                    inputProps={{
                        value: panNo || "",
                        onBlur: ({ target: { value } }) => validateInput('panNo', value),
                        error: isError('panNo'),
                        disabled: !isEdit

                    }}
                />
                {isError('panNo') ? (
                    <span className={themeClass.error}>
                        {formErrors['panNo']}
                    </span>
                ) : null}
            </GridItem>

            
            {!disabledRole?<GridItem xs={12} sm={12} md={4} alignItems="center"> 
            <br />
            <GridContainer  style={{justifyContent: 'center'}} alignItems="center" direction="row">
            {!isEdit?<Button round color="primary" onClick={(e)=>downloadUploadedFile(e, 'identity', 'pan', 'personalInfo')}>{!(Object.keys(pan).length>0)?'Upload':'Download PAN - '} {Object.keys(pan).length>0?pan.documentName.substring(0, 10):'PAN'} </Button>:null}
                {isEdit?<label className={themeClass.customFileUpload}>
                <input onChange={(e) => {getUploadedFile(e, 'identity', 'pan', 'personalInfo')}}  type="file" style={{display: 'none'}}/>
                    {selectPAN==''?<span>Select PAN </span>: selectPAN} 
                </label>:null}
                {isEdit?<Button disabled={!selectPAN} round color="primary" onClick={()=>{fileUploadHandler('pan')}}> Upload</Button>:null}
            </GridContainer>
            </GridItem>:null}
            {!disabledRole?<GridItem xs={12} sm={12} md={4}>
            <br />
            <GridContainer  style={{justifyContent: 'center',fontSize: '10px'}} alignItems="center" direction="row">
            {!isEdit?<Button round color="primary" onClick={(e)=>downloadUploadedFile(e, 'addressproof', 'aadhaarFront', 'personalInfo')}>{!(Object.keys(aadharFront).length>0)?'Upload':'Download aadhaar (Front) - '} {Object.keys(aadharFront).length>0?aadharFront.documentName.substring(0, 10):'aadhaar (Front)'}</Button>:null}
            {isEdit?<label className={themeClass.customFileUpload}>
                <input onChange={(e) => {getUploadedFile(e, 'addressproof', 'aadhaarFront', 'personalInfo')}}  type="file" style={{display: 'none'}}/>
                    {selectAadhaarFront==''?<span>Select aadhaar (Front) </span>: selectAadhaarFront}
                </label>:null}
                {isEdit?<Button disabled={!selectAadhaarFront}  round color="primary" onClick={()=>{fileUploadHandler('aadhaarFront')}}> Upload</Button>:null}
            </GridContainer>
            </GridItem>:null}
            {!disabledRole?<GridItem xs={12} sm={12} md={4}>
            <br />
            <GridContainer  style={{justifyContent: 'center'}} alignItems="center" direction="row">
            {!isEdit?<Button round color="primary" onClick={(e)=>downloadUploadedFile(e, 'addressproof', 'aadhaarBack', 'personalInfo')}>{!(Object.keys(aadharBack).length>0)?'Upload':'Download aadhaar (Back) - '} {Object.keys(aadharBack).length>0?aadharBack.documentName.substring(0, 10):'aadhaar (Back)'}</Button>:null}
            {isEdit?<label className={themeClass.customFileUpload}>
                <input onChange={(e) => {getUploadedFile(e, 'addressproof', 'aadhaarBack', 'personalInfo')}}  type="file" style={{display: 'none'}}/>
                    {selectAadhaarBack==''?<span>Select aadhaar (Back) </span>: selectAadhaarBack}
                </label>:null}
                {isEdit?<Button disabled={!selectAadhaarBack} round color="primary" onClick={()=>{fileUploadHandler('aadhaarback')}}> Upload</Button>:null}
            </GridContainer>
            </GridItem>:null}

            {!disabledRole?<GridItem xs={12} sm={12} md={6}>
                <CustomInput
                    labelText="Linked BQP/SP"
                    id="bqp"
                    formControlProps={{
                        fullWidth: true
                    }}
                    error={isError('bqp')}
                    onChange={({ target: { value } }) => (value ? updateKeyValueInState('bqp', value) : updateKeyValueInState('bqp', null))}
                    inputProps={{
                        value: bqp || '',
                        onBlur: ({ target: { value } }) => validateInput('bqp', value),
                        error: isError('bqp'),
                        disabled: true

                    }}
                />
            </GridItem>:null}

            {!disabledRole?<GridItem xs={12} sm={12} md={6}>
                <CustomInput
                    labelText={referredByText}
                    id="refer"
                    formControlProps={{
                        fullWidth: true
                    }}
                    error={isError('refer')}
                    onChange={({ target: { value } }) => (value ? updateKeyValueInState('refer', value) : updateKeyValueInState('refer', null))}
                    inputProps={{
                        value: refer || '',
                        onBlur: ({ target: { value } }) => validateInput('refer', value),
                        error: isError('refer'),
                        disabled: true

                    }}
                />
            </GridItem>:null}
            {isEdit&&!disabledRole?<GridItem xs={12} sm={6}>
                <Autocomplete 
                  options={bqpOptions}
                  classes={classes}
                  onChange={selectedBQP}
                  getOptionSelected={(option, value) => option.id === value.id}
                  getOptionLabel={option => option.label}
                  onInputChange = {searchBQPParams}
                  disabled= {!isEdit}
                  noOptionsText = ''
                  renderInput={params => (
                    <TextField
                        {...params}
                        name="linkBqp"
                        onBlur={({ target: { value } }) => validateInput('linkBqp', value)}
                        label="Update link BQP/SP"
                        margin="normal"
                        required={disabledRole}
                        placeholder="Start typing..."
                        onChange={({ target: { value } }) => (value ? updateKeyValueInState('linkBqp', value) : updateKeyValueInState('linkBqp', null))}
                        error={isError('linkBqp')}
                        fullWidth/>
                      )}
                />
                {isError('linkBqp') ? (
                    <span className={themeClass.error}>
                        {formErrors['linkBqp']}
                    </span>
                ) : null}
              </GridItem>: null}

              {isEdit&&!disabledRole?<GridItem xs={12} sm={6}>
                <Autocomplete 
                  key="referby"
                  value={referState}
                  options={userListOptions}
                  classes={classes}
                  onChange={selectedRefUser}
                  getOptionSelected={(option, value) => option.id === value.id}
                  getOptionLabel={option => option.label}
                  onInputChange = {searchUsersParams}
                  disabled= {!isEdit}
                  noOptionsText = ''
                  renderInput={params => (
                    <TextField
                        {...params}
                        label={updateReferText}
                        margin="normal"
                        placeholder="Start typing..."
                        fullWidth/>
                      )}
                />
              </GridItem>: null}

            <GridItem xs={12}>
                <GridContainer>

                    {/* Address Line 1 */}
                    <GridItem xs={12} sm={12} md={12}>
                        <CustomInput
                            labelText="Address Line"
                            id="addressLine1"
                            formControlProps={{
                                fullWidth: true
                            }}
                            error={isError('addressLine1')}
                            onChange={({ target: { value } }) => (value ? updateKeyValueInState('addressLine1', value) : updateKeyValueInState('addressLine1', null))}
                            inputProps={{
                                value: addressLine1 || '',
                                onBlur: ({ target: { value } }) => validateInput('addressLine1', value),
                                error: isError('permanent', 'addressLine1'),
                                disabled: !isEdit

                            }}
                        />
                        {isError('addressLine1') ? (
                            <span className={themeClass.error}>
                                {formErrors.addressLine1}
                            </span>
                        ) : null}
                    </GridItem>


                    {/* City */}
                    <GridItem xs={12} sm={12} md={3}>
                        <CustomInput
                            labelText="City"
                            id="city"
                            formControlProps={{
                                fullWidth: true
                            }}
                            // isRequired
                            error={isError('city')}
                            onChange={({ target: { value } }) => (value ? updateKeyValueInState('city', value) : updateKeyValueInState('city', null))}
                            inputProps={{
                                value: city || '',
                                onBlur: ({ target: { value } }) => validateInput('city', value),
                                error: isError('city'),
                                disabled: !isEdit

                            }}
                        />
                        {/* {isError('city') ? (
                            <span className={classes.error}>
                                {formErrors.city}
                            </span>
                        ) : null} */}
                    </GridItem>

                    {/* District */}
                    <GridItem xs={12} sm={12} md={3}>
                        <CustomInput
                            labelText="District"
                            id="district"
                            formControlProps={{
                                fullWidth: true
                            }}
                            error={isError('district')}
                            onChange={({ target: { value } }) => (value ? updateKeyValueInState('district', value) : updateKeyValueInState('district', null))}
                            inputProps={{
                                value: district || '',
                                onBlur: ({ target: { value } }) => validateInput('district', value),
                                error: isError('district'),
                                disabled: !isEdit

                            }}
                        />
                        {isError('district') ? (
                            <span className={themeClass.error}>
                                {formErrors.district}
                            </span>
                        ) : null}
                    </GridItem>

                    {/* State */}
                    <GridItem xs={12} sm={12} md={3}>
                        <CustomInput
                            labelText="State"
                            id="state"
                            formControlProps={{
                                fullWidth: true
                            }}
                            // isRequired
                            error={isError('state')}
                            onChange={({ target: { value } }) => (value ? updateKeyValueInState('state', value) : updateKeyValueInState('state', null))}
                            inputProps={{
                                value: state || '',
                                onBlur: ({ target: { value } }) => validateInput('state', value),
                                error: isError('state'),
                                disabled: !isEdit

                            }}
                        />
                        {/* {isError('state') ? (
                            <span className={themeClass.error}>
                                {formErrors.state}
                            </span>
                        ) : null} */}
                    </GridItem>

                    {/* Country */}
                    <GridItem xs={12} sm={12} md={3}>
                        <CustomInput
                            labelText="Country"
                            id="country"
                            formControlProps={{
                                fullWidth: true
                            }}
                            // isRequired
                            error={isError('country')}
                            onChange={({ target: { value } }) => (value ? updateKeyValueInState('country', value) : updateKeyValueInState('country', null))}
                            inputProps={{
                                value: country || '',
                                onBlur: ({ target: { value } }) => validateInput('country', value),
                                error: isError('country'),
                                disabled: !isEdit

                            }}
                        />
                        {/* {isError('country') ? (
                            <span className={themeClass.error}>
                                {formErrors.country}
                            </span>
                        ) : null} */}
                    </GridItem>

                    {/* Pincode */}
                    <GridItem xs={12} sm={12} md={3}>
                        <CustomInput
                            labelText="Pincode"
                            id="pinCode"
                            formControlProps={{
                                fullWidth: true
                            }}
                            error={isError('pinCode')}
                            onChange={({ target: { value } }) => (value ? updateKeyValueInState('pinCode', value) : updateKeyValueInState('pinCode', null))}
                            inputProps={{
                                value: pinCode || '',
                                onBlur: ({ target: { value } }) => validateInput('pinCode', value),
                                error: isError('pinCode'),
                                type: 'number',
                                disabled: !isEdit

                            }}
                        />
                        {isError('pinCode') ? (
                            <span className={themeClass.error}>
                                {formErrors.pinCode}
                            </span>
                        ) : null}
                    </GridItem>
                </GridContainer>
                <br />
            {!disabledRole?
            <GridContainer>
              <GridItem xs={12} sm={12}>
                <Typography variant="h6" align="center">Educational Qualifications</Typography>
              </GridItem>
                <GridItem xs={12} sm={6}>
                  {/* {renderSelect({
                    name: "qualification", label: "Highest Qualification", options: highestQualification, touched, errors,
                    handleChange, values, handleBlur
                  })} */}
                  <SelectOption
                    id="highestQualification"
                    labelText="Highest Qualification"
                    selectOptions={highestQualification}
                    error={isError('highestQualification')}
                    formControlProps={{
                        fullWidth: true
                    }}
                    onSelectChange={({ target: { value } }) => (value ? updateKeyValueInObjectState('educationInfo', 'highestQualification', value) : updateKeyValueInObjectState('educationInfo', 'highestQualification', null))}
                    inputProps={{
                        value: educationInformation.highestQualification || '',
                        onBlur: ({ target: { value } }) => validateInput('highestQualification', value),
                        error: isError('highestQualification'),
                        disabled: !isEdit
                    }}
                    />
                    {isError('highestQualification') ? (
                        <span className={classes.error}>
                            {formErrors['highestQualification']}
                        </span>
                    ) : null}
                </GridItem> 
              
                <GridItem xs={12} sm={6}>
                   <SelectOption
                    id="yearOfCompletion"
                    name="yearOfCompletion"
                    labelText="Year of Completion"
                    selectOptions={yearOptions}
                    error={isError('yearOfCompletion')}
                    formControlProps={{
                        fullWidth: true
                    }}
                    onSelectChange={({ target: { value } }) => (value ? updateKeyValueInObjectState('educationInfo', 'yearOfCompletion', value) : updateKeyValueInObjectState('educationInfo', 'yearOfCompletion', null))}
                    inputProps={{
                        value: educationInformation.yearOfCompletion || '',
                        error: isError('yearOfCompletion'),
                        disabled: !isEdit
                    }}
                    />
                    {isError('yearOfCompletion') ? (
                        <span className={classes.error}>
                            {formErrors['yearOfCompletion']}
                        </span>
                    ) : null}
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                <br />
                <GridContainer  style={{justifyContent: 'center'}} alignItems="center" direction="row">
                {!isEdit?<Button round color="primary" onClick={(e)=>downloadUploadedFile(e, 'certificates', 'degree_certificate', 'educationInfo')}> {!(education&&Object.keys(education).length>0)?'Upload':'Download educational certificate - '} {education&&Object.keys(education).length>0?education.documentName.substring(0, 10):'educational certificate'}</Button>:null}
                {isEdit?<label className={themeClass.customFileUpload}>
                        <input onChange={(e) => {getUploadedFile(e, 'certificates', 'degree_certificate', 'educationInfo')}}  type="file" style={{display: 'none'}}/>
                        {selectCertificate==''?<span>Select educational certificate </span>: selectCertificate}
                    </label>:null}
                    {isEdit?<Button disabled={!selectCertificate}  round color="primary" onClick={()=>{fileUploadHandler('educationInfo')}}> Upload</Button>:null}
                </GridContainer>
                </GridItem>
            </GridContainer>:null}
            <br />
            {!disabledRole?<GridContainer>
              <GridItem xs={12} sm={12}>
                <Typography variant="h6" align="center">Bank Details</Typography>
              </GridItem>
                <GridItem xs={12} sm={6}>
                  <CustomInput
                        labelText="Name of Account Holder"
                        id="accountHolderName"
                        formControlProps={{
                            fullWidth: true
                        }}
                        error={isError('accountHolderName')}
                        onChange={({ target: { value } }) => (value ? updateKeyValueInObjectState('bankInfo', 'accountHolderName', value) : updateKeyValueInObjectState('bankInfo', 'accountHolderName', null))}
                        inputProps={{
                            value: bankInformation.accountHolderName || '',
                            onBlur: ({ target: { value } }) => validateInput('accountHolderName', value),
                            error: isError('accountHolderName'),
                            type: 'text',
                            disabled: !isEdit

                        }}
                    />
                    {isError('accountHolderName') ? (
                        <span className={themeClass.error}>
                            {formErrors.accountHolderName}
                        </span>
                    ) : null}
                </GridItem>
                <GridItem xs={12} sm={6}>
                   <CustomInput
                        labelText="Bank Account Number"
                        id="bankAccountNo"
                        formControlProps={{
                            fullWidth: true
                        }}
                        error={isError('bankAccountNo')}
                        onChange={({ target: { value } }) => (value ? updateKeyValueInObjectState('bankInfo', 'bankAccountNo', value) : updateKeyValueInObjectState('bankInfo', 'bankAccountNo', null))}
                        inputProps={{
                            value: bankInformation.bankAccountNo || '',
                            onBlur: ({ target: { value } }) => validateInput('bankAccountNo', value),
                            error: isError('bankAccountNo'),
                            type: 'number',
                            disabled: !isEdit

                        }}
                    />
                    {isError('bankAccountNo') ? (
                        <span className={themeClass.error}>
                            {formErrors.bankAccountNo}
                        </span>
                    ) : null}
                </GridItem>
                <GridItem xs={12} sm={6}>
                   <CustomInput
                        labelText="IFSC Code"
                        id="ifscCode"
                        formControlProps={{
                            fullWidth: true
                        }}
                        error={isError('ifscCode')}
                        onChange={({ target: { value } }) => (value ? updateKeyValueInObjectState('bankInfo', 'ifscCode', value) : updateKeyValueInObjectState('bankInfo', 'ifscCode', null))}
                        inputProps={{
                            value: bankInformation.ifscCode || '',
                            onBlur: ({ target: { value } }) => validateInput('ifscCode', value),
                            error: isError('ifscCode'),
                            type: 'text',
                            disabled: !isEdit

                        }}
                    />
                    {isError('ifscCode') ? (
                        <span className={themeClass.error}>
                            {formErrors.ifsc}
                        </span>
                    ) : null}
                </GridItem>
                <br />
                <GridItem xs={12} sm={12} md={12}>
                <br />
                <GridContainer  style={{justifyContent: 'center'}} alignItems="center" direction="row">
                {!isEdit?<Button round color="primary" onClick={(e)=>downloadUploadedFile(e, 'bankdoc', 'chequeleaf_front', 'bankInfo')}>{!(bank&&Object.keys(bank).length>0)?'Upload':'Download cancelled cheque'} {bank&&Object.keys(bank).length>0?bank.documentName.substring(0, 10):'cancelled cheque'}</Button>:null}
                {isEdit?<label className={themeClass.customFileUpload}>
                        <input onChange={(e) => {getUploadedFile(e, 'bankdoc', 'chequeleaf_front', 'bankInfo')}}  type="file" style={{display: 'none'}}/>
                        {selectCheque==''?<span>Select cancelled cheque </span>: selectCheque}
                    </label>:null}
                    {isEdit?<Button disabled={!selectCheque} round color="primary" onClick={()=>{fileUploadHandler('bankInfo')}}> Upload</Button>:null}
                </GridContainer>
                </GridItem>
            </GridContainer>:null}
            </GridItem> 
        </GridContainer>
    );

}