import * as actions from './types';

export function fetchPosPayoutWatcher(param, resolve =()=>{}, reject =()=>{}) {
    return { type: actions.FETCH_ALL_PAYOUT_WATCHER, payload: param, resolve, reject };
  }

export function fetchPosPolicyWiseEarningsWatcher(param) {
  return {type: actions.FETCH_POLICYWISE_EARNINGS_WATCHER , payload: param};
}

  export function updatePosPayoutWatcher(param, resolve, reject) {
    return {type: actions.UPDATE_POS_PAYOUT_WATCHER, payload: param, resolve, reject};
}

  export function posPayoutList(posPayoutList) {
      return {type: actions.POS_PAYOUT, posPayoutList}
  }

  export function policyWiseEarnings(policyWiseEarnings) {
    return {type: actions.POLICYWISE_EARNINGS, policyWiseEarnings};
  }