import * as React from "react";
import Admin from '../DashBoard/Admin';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { fetchPolicySoldWatcher } from "../../store/actions/posAction";
import PolicySoldList from './PolicySoldList';
import { isEmpty } from "../../utils/common";


class PolicySoldPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
    }
    // this.props.fetchPolicySoldWatcher({});
    // props.fetchPolicySoldWatcher('5d7643e44594000ee3a1d75c');
  }
  componentWillMount() {
    if(isEmpty(this.props.policySoldList))
    this.props.fetchPolicySoldWatcher({})
  }

  getDetails = (event, {proposalId, insuranceType}) => {
    event.preventDefault();
    this.props.history.push({ pathname: `/policy-details/${insuranceType}/${proposalId}`, state: { proposalId, insuranceType } })
    console.log("proposal id is ", proposalId);
  }

  render() {
    const { policySoldList = {} } = this.props;
    const {policiesSold = []} = policySoldList;

    return (
      <Admin>
        {/* <Paper>
          <GridContainer>
            {
              policySoldList.map(policy => (
                <GridItem xs="12" sm="12" md="6">
                  <PolicySoldCard
                    cardData={policy} />
                </GridItem>
              ))
            }
          </GridContainer>
        </Paper> */}
         <PolicySoldList
          policySoldList={policiesSold}
          getDetails={this.getDetails}
         />
      </Admin>
      
    );
  }
}
function mapStateToProps(state) {
  const { posReducer } = state;
  return {
    policySoldList: posReducer.policySoldList

  };
}
// mapping dispatch functions to the props of LoginForm component
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      fetchPolicySoldWatcher
      // add other watcher sagas to this object to map them to props
    },
    dispatch
  )
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PolicySoldPage);