import { all } from 'redux-saga/effects'
import sessionSaga from './sessionSaga';
import posSagas from './posSaga';
import posCommissionSaga from './posCommissionSaga'
import dsaSagas from './dsaSaga';
import productSagas from './productSaga'
import payoutSaga from './payoutSaga';
import notificationSaga from './notificationSaga';
import payoutFrequencySaga from './payoutFrequencySaga';
import specialUserSaga from './specialUserSaga';

export default function* rootSaga() {
  yield all([
    sessionSaga(),
    posSagas(),
    posCommissionSaga(),
    dsaSagas(),
    productSagas(),
    payoutSaga(),
    notificationSaga(),
    payoutFrequencySaga(),
    specialUserSaga()
  ])
}
