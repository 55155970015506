import * as actions from './types';

// Worker triggering actionCreators
export function fetchposListWatcher() {
  return { type: actions.POS_LIST_WATCHER };
}

export function fetchposOnboardingListWatcher() {
  return { type: actions.POS_ONBOARDING_LIST_WATCHER };
}

export function fetchposDetailWatcher(posId) {
  return {
    type: actions.POS_DETAIL_WATCHER,
    payload: posId,
  };
}

export function fetchPosEarningWatcher(param, resolve, reject) {
  return {
    type: actions.FETCH_POSP_EARNING_WATCHER,
    payload: param, resolve, reject
  }
}

export function updatePosEducationInfoWatcher(posDetails, resolve, reject) {
  return { type: actions.UPDATE_EDUCATION_INFO_WATCHER, payload: posDetails, resolve, reject };
}

export function updatePosBankInfoWatcher(posDetails, resolve, reject) {
  return { type: actions.UPDATE_BANK_INFO_WATCHER, payload: posDetails, resolve, reject };
}

export function updatePosPersonalInfoWatcher(posDetails, resolve, reject) {
  return { type: actions.UPDATE_PERSONAL_INFO_WATCHER, payload: posDetails, resolve, reject };
}
export function updatePosBusinessInfoWatcher(posDetails, resolve, reject) {
  return { type: actions.UPDATE_BUSINESS_INFO_WATCHER, payload: posDetails, resolve, reject };
}

export function updatePosCertificationInfoWatcher(posDetails, resolve, reject) {
  return { type: actions.UPDATE_CERTIFICATION_INFO_WATCHER, payload: posDetails, resolve, reject };
}

export function updatePosApprovalStatusWatcher(param, resolve, reject) {
  return { type: actions.UPDATE_POS_APPROVAL_STATUS_WATCHER, payload: param, resolve, reject };
}

export function fetchPolicySoldWatcher(param) {
  return { type: actions.FETCH_POLICY_SOLD_WATCHER, payload: param };
}

export function updatePosEligibilityStatusWatcher(param, resolve, reject) {
  return { type: actions.UPDATE_POS_ELIGIBILITY_STATUS_WATCHER, payload: param, resolve, reject };
}

export function getDocumentsWatcher(param, resolve, reject) {
  return { type: actions.DOCUMENTS_GET_WATCHER, payload: param, resolve, reject };
}

export function uploadDocumentWatcher(param, resolve, reject) {
  return { type: actions.DOCUMENT_UPLOAD_WATCHER, payload: param, resolve, reject };
}

export function pospApplicationFormWatcher(param, resolve, reject) {
  console.log('param', param)
  return { type: actions.APPLICATION_FORM_WATCHER, payload: param, resolve, reject };
}

export function updatePospRoleWatcher(param,resolve, reject) {
  return {type: actions.UPDATE_POSP_ROLE_WATCHER, payload: param, resolve, reject};
}

export function updatePospSeniorWatcher(param, resolve,reject) {
  return {type: actions.UPDATE_POSP_SENIOR_WATCHER, payload: param, resolve, reject};
}

export function uploadPospUtilityWatcher(param, resolve, reject) {
  return {type: actions.UPLOAD_POSP_UTILITY_WATCHER, payload: param, resolve, reject};
}

export function fetchROTUserByPhoneWatcher(param) {
  return ({
    type: actions.ROT_USERS_WATCHER,
    payload: param
  });
}


export function posList(posList) {
  return { type: actions.POS_LIST, posList }
}

export function posOnboardingList(posOnboardingList) {
  return { type: actions.POS_ONBOARDING_LIST, posOnboardingList }
}

export function posDetail(posDetail) {
  return { type: actions.POS_DETAIL, posDetail }
}

export function policySoldList(policySoldList) {
  return { type: actions.POLICY_SOLD_LIST, policySoldList }
}

export function posEarning(pospEarning) {
  return { type: actions.POSP_EARNING, pospEarning }
}

export function rotUsersList(rotUsersList) {
  return { type: actions.ROT_USERS_LIST, rotUsersList }
}



