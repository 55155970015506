import {
  proxy, emailCheckRegex, phoneNumberRegex, aadharNumberRegex, alphaNumericWithUnderHypenRegex,
  panNoRegex, bankNoRegex, alphaNumericRegex, charRegex, dateCheckRegex, pinCodeRegex, imageUrlRegex
} from './constants'
import { ExportToCsv } from 'export-to-csv'
import moment from 'moment'

export function isEmpty (obj) {
  let isempty = false
  const type = typeof obj

  isempty = isempty || !obj
  isempty = isempty || (type === 'undefined') // if it is undefined
  isempty = isempty || (obj === null) // if it is null
  isempty = isempty || (type === 'string' && (obj.trim() === '')) // if the string is empty
  isempty = isempty || (obj === false || obj === 0) // if boolean value returns false
  isempty = isempty || (Array.isArray(obj) && obj.length === 0) // if array is empty
  isempty = isempty || (type === 'object' && Object.keys(obj).length === 0) // if object is empty

  return isempty
}

export function isEmailValid (value) {
  return value.match(emailCheckRegex)
}

export function isValidImageUrl(value) {
  return value.match(imageUrlRegex);
}

export function isPinCodeValid (value) {
  return value.match(pinCodeRegex)
}

export function isPhoneNumberValid (value) {
  return value.match(phoneNumberRegex)
}

export function isDateValid (value) {
  return String(value).match(dateCheckRegex)
}

export function isAadharNumberValid (value) {
  return value.match(aadharNumberRegex)
}

export function isPanNumberValid (value) {
  return value.match(panNoRegex)
}

export function isBankNumberValid (value) {
  return value.match(bankNoRegex)
}

export function isAlphaNumeric (value) {
  return value.match(alphaNumericRegex)
}

export function isAlphaNumericWithHypenUnderScore(value){
  if(value)
  return value.match(alphaNumericWithUnderHypenRegex);
  else return true
}

export function hasOnlyCharacters (value) {
  return value.match(charRegex)
}

export const setCookie = (cookieName, cookieValue, expiryDays) => {
  if (typeof window === 'undefined') return
  let expires = ''
  if (expiryDays) {
    const d = new Date()
    d.setTime((d.getTime() + expiryDays) * (24 * 60 * 60 * 1000))
    expires = `expires=${d.toUTCString()};`
  }
  document.cookie = `${cookieName}=${cookieValue};${expires}path=/`
}
/*eslint-disable*/
export const getCookieFromString = (key, cookieString) => {
  return cookieString.replace(
    new RegExp(`(?:(?:^|.*;\\s*)${key}\\s*\\=\\s*([^;]*).*$)|^.*$`),
    "$1"
  );
};

export const sortBy = (a, b, obj) => {
  if (a[obj] < b[obj]) { return -1 }
  if (a[obj] > b[obj]) { return 1 }
  return 0
}

export function customParseFloat(number){
  if(isNaN(parseFloat(number)) === false){
    let toFixedLength = 0;
    let str = String(number);

    // You may add/remove seperator according to your needs
    [".", ","].forEach(seperator=>{
      let arr = str.split(seperator);
      if( arr.length === 2 ){
        toFixedLength = arr[1].length;
      }
    })

    return parseFloat(str).toFixed(toFixedLength);
  }

  return number; // Not a number, so you may throw exception or return number itself
}

/* Sample getCookie function with one mandatory param key and optional param cookieString
 * When cookie string is passed and it is a non empty string the cookie is 
   extracted from the respective Cookie String
 * Otherwise it extracts the cookie from the document object if present 
 */
/*eslint-disable*/
export const getCookie = (key, cookieString) => {
  if (cookieString) {
    return cookieString.replace(
      new RegExp(`(?:(?:^|.*;\\s*)${key}\\s*\\=\\s*([^;]*).*$)|^.*$`),
      "$1"
    );
  } else if (typeof window === "undefined") return "";
  return document.cookie.replace(
    new RegExp(`(?:(?:^|.*;\\s*)${key}\\s*\\=\\s*([^;]*).*$)|^.*$`),
    "$1"
  );
};

export const getCompleteCookieString = (cookieString = "") => {
  if (cookieString) return cookieString;
  else if (typeof window === "undefined") return cookieString;
  return document.cookie;
};

export const deleteCookie = cname => {
  const expires = "expires=Thu, 01-Jan-70 00:00:01 GMT;path=/";
  document.cookie = `${cname}=;${expires}`;
};

export const deleteAllCookies = () => {
  var cookies = document.cookie.split(";");

  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];
    var eqPos = cookie.indexOf("=");
    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01-Jan-70 00:00:01 GMT;path=/";
  }
}

export const createUrl = (url) => {
  console.log(proxy + url)
  return proxy + url;
}

export const exportCsv = (param)  => {

  const {fileName, title, data} = param;

  const options = { 
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true, 
    showTitle: false,
    title: title,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
    filename: fileName
    // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
  };

  const csvExporter = new ExportToCsv(options);

  csvExporter.generateCsv(data);
}

export const generateOtp = (phone) => {
let otp =[];
let phoneArr = phone.split('');
for(let i=0; i<=4;i++){
  const key = Math.floor((phoneArr[i]*2 + phoneArr[i+1]*2)/4);
  
  otp.push(key);
};
otp.push(Math.floor((parseInt(phoneArr[0]) + parseInt(phoneArr[5]))/2));
let result = otp.map(no => parseInt(no, 10));

return result;
}

export const changeDateFormat = (value, format) =>{
  if(isDateValid(value)) return value;
  else return moment(value).format(format);
}
export const changeDateFormatFromProvidedFormat = (value, providedFormat, toFormat) => {
  if(!isEmpty(value)) {
    var date = moment(value, providedFormat)
    return date.format(toFormat)
  }
  return ''
}
export const changeEpoachDateToFormat =(value, format) => {
  return moment(value).format(format);
}

export const changeDateToEpoach =(value) => {
  const date = new Date(value);
  return date.getTime();

}

export const getStatus = (pos = {}) => {
  let status = null;
  const appStatus = pos.approvalStatus;
  if (appStatus === 'APPROVED' || appStatus === 'REJECTED' || appStatus === 'ONBOARDED')
    return appStatus;

  else {
    Object.keys(pos).map(key => {
      if (typeof pos[key] === 'object') {
        if (pos[key] && !pos[key].isComplete) status = 'INCOMPLETE PROFILE'
      }
    });
    if (pos.certification && !pos.certification.isExamPassed) {
      status = status ? 'INCOMPLETE PROFILE / EXAM' : 'EXAM PENDING';
    }
  }
  return status;
}
