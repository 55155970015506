import * as React from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import PosEligibilityCheckList from "./PosEligibilityCheckList";
import AlertBox from "../../components/DialogBox/DialogBox";
import Admin from '../DashBoard/Admin';
import { fetchposListWatcher, updatePosEligibilityStatusWatcher } from "../../store/actions/posAction";
import { getCookie, isEmpty } from "../../utils/common";
import { ELIGIBILITY_STATUS } from "../../utils/constants";

class PosEligibilityCheckPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      obj: {}

    }
  }
  componentDidMount() {
    if(isEmpty(this.props.posList))
    this.props.fetchposListWatcher();
  }

  getDetails = (event, id) => {
    event.preventDefault();
    this.props.history.push({ pathname: `/pos-detail/${id}`, state: { posId: id } })
    console.log("pos details id", id);
  }

  //While updating any status
  uploadStatus = (event, param) => {
    event.preventDefault();
    const adminUserId = getCookie("adminUserId");
    param.adminUserId = adminUserId;

    // this.openModal(param).then(() => {
    new Promise((resolve, reject) => {
      this.props.updatePosEligibilityStatusWatcher(param, resolve, reject);
    })
      //  .then(event => {
      //    console.log("Status updated successfully");
      //  })
      .catch(error => {
        console.log("Error while updating  aaasdfefdfgsdfhs the status");
      });

    // })
    // .catch( event => {
    //   console.log("Status update cancelled");
    // })
  }

  handleClose = (event, param) => {
    event.preventDefault();

    this.setState({
      open: !this.state.open,
    });

    if (param === 'okay') {
      const args = { ...this.state.param };
      args.eligibilityStatus = args.status ? ELIGIBILITY_STATUS.ACCEPTED : ELIGIBILITY_STATUS.REJECTED;
      delete args.status;
      this.uploadStatus(event, args);
      console.log("okay was pressed");
    }
    else {
      this.setState({
        param
      });
    }
  }

  // openModal = (event,posId, approvalStatus) => {
  //   event.preventDefault();
  //   const adminUserId = getCookie("adminUserId");

  //   const param={
  //     adminUserId,
  //     approvalStatus,
  //     posId
  //   };
  // this.setState({
  //   openModal: true,
  // },()=>{

  // })
  // }

  render() {
    const { posList = [] } = this.props;
    const dialogTitle = "Are you sure, you want to update the Eligibility Status?";
    const dialogDescription = "";

    return (
      <Admin>
        <PosEligibilityCheckList
          posList={posList}
          getDetails={this.getDetails}
          uploadStatus={this.uploadStatus}
          handleClose={this.handleClose}
        />
        <AlertBox
          open={this.state.open}
          handleClose={this.handleClose}
          dialogTitle={dialogTitle}
          dialogDescription={dialogDescription}
        />
      </Admin>
    );
  }
}
function mapStateToProps(state) {
  const { posReducer } = state;
  return {
    posList: posReducer.posList

  };
}
// mapping dispatch functions to the props of LoginForm component
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      fetchposListWatcher,
      updatePosEligibilityStatusWatcher
      // add other watcher sagas to this object to map them to props
    },
    dispatch
  )
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PosEligibilityCheckPage);