import { push } from 'react-router-redux'
import { call, put, takeLatest, all, fork } from 'redux-saga/effects'
import { authError, clearSession } from '../actions/loginAction'
import * as actions from '../actions/types'
import axios from '../../utils/axios'
import urls from '../../utils/urls'
import { createUrl, setCookie, isEmpty, deleteAllCookies } from '../../utils/common'

function loginApi (authParams) {
  return axios.request({
    method: 'post',
    url: createUrl(urls.BASE_URL + urls.login.BASE_URL),
    headers: {
      'Content-Type': 'application/json',
      email: authParams.email,
      password: authParams.password,
    },
    data: {brokerName: window.env.BROKER_NAME}
  })
}

// Saga function that handles the side effect when the loginActionWatcher is triggered
export function * loginActionEffect (loginAction) {
  const { payload, resolve, reject } = loginAction

  try {
    const { data } = yield call(loginApi, payload)

    if (!isEmpty(data) && data.userexists) {
      console.log('user exists')
      const brokerId = !isEmpty(data.broker) ? data.broker.brokerId : '000000'
      setCookie('token', data.token, 1)
      setCookie('adminUserId', data.adminUserId, 1)
      setCookie('brokerId', brokerId, 1)
      yield put(push('/pos-list'))

      console.log('isResolve', resolve)
      if (resolve) resolve()
    } else {
      reject(data)
    }
  } catch (e) {
    //   alert.error(e.message || 'Authentication Error');
    yield put(authError(e))
    console.log('user is invalid')
    if (reject) reject(e)
  }
}
// Saga function that is initiated in the beginning to be able to listen to LOG_IN_WATCHER action
export function * loginActionWatcher () {
  yield takeLatest(actions.LOG_IN_WATCHER, loginActionEffect)
}

/**
 * Logout Operation using saga
 */
// Saga function that handles the side effect when the logoutActionWatcher is triggered
export function * logoutActionEffect () {
  try {
    localStorage.clear()
    yield put(clearSession())
    deleteAllCookies()
    yield put(push('/login'))
  } catch (e) {
    yield put(authError(e))
  }
}
// Saga function that is initiated in the beginning to be able to listen to LOG_OUT_WATCHER action
export function * logoutActionWatcher () {
  yield takeLatest(actions.LOG_OUT_WATCHER, logoutActionEffect)
}


export default function * rootSaga() {
  yield all([
    fork(loginActionWatcher),
    fork(logoutActionWatcher)
  ])
}