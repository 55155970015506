/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
// core components
import GridItem from "../../components/Grid/GridItem";
import GridContainer from "../../components/Grid/GridContainer";
import PolicySoldTable from "./PolicySoldTable";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import Search from "@material-ui/icons/Search";
import CustomInput from "../../components/CustomInput/CustomInput";
import Button from "../../components/CustomButtons/Button";
import styleHeader from "../../assets/jss/componentsStyles/headerLinksStyle";
import { isEmpty, exportCsv, changeEpoachDateToFormat } from "../../utils/common";
import ImportExportIcon from '@material-ui/icons/ImportExport';
import FilterListIcon from '@material-ui/icons/FilterList';
import FuzzySearch from 'fuzzy-search'
import { posList } from "store/actions/posAction";
import { proposalDetails } from "store/actions/productAction";
import { isNull } from "lodash";
import { isValid } from "date-fns";


const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "Montserrat",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  inputSearchType: {
    color: "white",
    padding: "0",
  },
  paddingStyle: {
    padding: "0 12px 0"
  }

};
function createData(companyLogo,insuranceType, policyNo, dateOfPayment, policyIssuedOnline, pospName, planName, sumInsured, premium, proposalId) {
  return { companyLogo,insuranceType, policyNo, dateOfPayment, policyIssuedOnline, pospName, planName, sumInsured, premium, proposalId }
}

const useStyles = makeStyles(styles);
const headerStyle = makeStyles(styleHeader);

export default function PolicySoldList(props) {
  const classes = useStyles();
  const headerClass = headerStyle();
  const { policySoldList = [], getDetails } = props;
  let rows = [];
  // const detailButton =  (<Button color="primary">Details</Button>)
  policySoldList.forEach(policy => {
    const companyLogo = policy.companyLogo;
    const policyNo  = policy.policyNo;
    let {insuranceType = '', policyType='' } = policy;
    let planName =''
    if(isEmpty(insuranceType) && policy.ins_type){
      insuranceType = policy.ins_type;
    }else if(policy.planDetails) {
      insuranceType = policy.planDetails.insuranceType;
    }
    //let policyType = '';

    if(insuranceType && insuranceType.toLowerCase() !== 'health') {

      if(policyType && policy.requestQuote) {
        const {policyType:plcyType =[]} = policy.requestQuote;
        for(let i=0;i< plcyType.length;i++){
          if(plcyType[i]['selection']){
            policyType=plcyType[i]['type'];
            break;
          }
        }
      }
      planName = insuranceType.toLowerCase() === 'motor_4w' ? `Car Insurance ( ${policyType})`   : `Bike Insurance ( ${policyType})`
    }
    else {
      planName = policy.planName || '';
      if(isEmpty(planName)){
        planName = policy.planDetails && policy.planDetails.planName;
      }
    }
    let sumInsuredInLakhs = policy.sumInsuredInLakhs || (parseFloat(parseInt(policy.IDV)/100000).toPrecision(2) + ' Lakhs');
    const premium = policy.premium || policy.premiumAnnually;
    
    const doP = !isEmpty(policy.dateOfPayment) ?  changeEpoachDateToFormat(parseInt(policy.dateOfPayment), 'DD/MM/YYYY') : '';

    let modeOfSale = '';
    if (policy.policyIssuedOnline){
      if (policy.policyIssuedOnline.toLowerCase() === 'yes'){ modeOfSale = 'Online'}
      else if (policy.policyIssuedOnline.toLowerCase() === 'no'){ modeOfSale = 'Offline'};
    };
   
    let row = createData(companyLogo, insuranceType || 'Health', policyNo, doP, modeOfSale, policy.pospName, planName,sumInsuredInLakhs,  premium, policy.proposalId);
    rows.push(row);
  });
  // POSP Name
  // 2. Policy Number
  // 3. Insurer
  // 4. Plan Name
  // 5. Sum Insured
  // 6. Premium

  const [filteredList, setList] = React.useState();

  const [showInput, toggleInput] = React.useState(false);


  React.useEffect(() => {
    setList(rows) // api.data.count from api after update store
  }, [policySoldList])
  //let newList = [...rows];

  React.useEffect(() => { document.getElementById("searchInput") && document.getElementById("searchInput").focus() }, [showInput])

  const filterList = (e) => {
    let currentList = []
    let newList = []

    // If the search bar isn't empty
    if (e.target.value !== '') {
      // Assign the original list to currentList
      currentList = rows
      const searcher = new FuzzySearch(currentList, ['policyNo', 'pospName', 'planName', 'sumInsured', 'premium', 'proposalId'], {
        caseSensitive: false
      })
      newList = searcher.search(e.target.value.toLowerCase())
    } else {
      // If the search bar is empty, set newList to original task list
      newList = [...rows]
    }

    setList(newList)
  }

  const exportPosp = (event) => {
    event.preventDefault();


    const data = []
    policySoldList.filter(policy=>!isEmpty(policy.proposalId)).forEach(policy => {
      let toShow = []
      const {
        appointeeDetails = [],
        approvedAdminEmail = {},
        approvedByAdmin = '',
        approvedByAdminDate = '',
        companyLogo = '',
        dateOfPayment = '',
        dateOfPolicyIssued = '',
        dateOfPolicyIssuedEpoch = '',
        isAssistedSales = '',
        memberDetails = [],
        modeOfPayment = '',
        nomineeDetails = {},
        paymentUrl = '',
        planDetails = {},
        policyDocumentLink = '',
        policyIssuanceType = '',
        policyIssuedOnline = '',
        policyNo = '',
        pospName = '',
        premium = '',
        productId = '',
        proposalAmount = '',
        proposalCreatedDate = '',
        proposalCreatedDateEpoch = '',
        proposalId = '',
        proposalStatus = '',
        proposerDetails = {},
        sumInsured = '',
        sumInsuredInLakhs = '',
        underWriting = '',
        updatePolicyDate = '',
        userId = ''
      } = policy;

//      if(appointeeDetails && !isNull(appointeeDetails)){
//      appointeeDetails.forEach(objs => {
//         if (objs) {
//           Object.keys(objs).filter(key => key !== '_id').forEach(key => { if (objs[key] !== null) toShow.push({ [key]: objs[key] ||'' })});
//         }
//      })};

      [nomineeDetails, planDetails, proposerDetails].forEach(objs => {
        if (!isEmpty(objs)) {
          Object.keys(objs).filter(key => key !== '_id').forEach(key => toShow.push({ [key]: objs[key] ||'' }));
        }
      });

      memberDetails.forEach(member => {
        if (!isEmpty(member)) {
          Object.keys(member).filter(key => (key !== '_id' && key !=='address')).forEach(key => toShow.push({ [key]: member[key] ||'' }));
          Array.isArray(member.address) && member['address'].forEach(address => {Object.keys(address).filter(key => (key !== '_id')).forEach(key => toShow.push({ [key]: address[key] ||'' }));
          })
        }
      });

      toShow.push({'Approved Admin Email': approvedAdminEmail || ''});
      toShow.push({'Appoved by Admin': approvedByAdmin || ''});
      toShow.push({'Approved by Admin Date': approvedByAdminDate || ''});
      toShow.push({'Company Logo': companyLogo || ''});
      toShow.push({'Date Of Payment': dateOfPayment || ''});
      toShow.push({'Date Of Policy Issued': dateOfPolicyIssued || ''});
      toShow.push({'Date Of Policy Issed Epoch': dateOfPolicyIssuedEpoch || ''});
      toShow.push({'Is Assisted Sales': isAssistedSales || ''});
      toShow.push({'Mode Of Payment': modeOfPayment || ''});
      toShow.push({'Payment URL': paymentUrl || ''});
      toShow.push({'Policy Document Link': policyDocumentLink || ''});
      toShow.push({'Policy Issuance Type': policyIssuanceType || ''});
      toShow.push({'Policy Issued Online': policyIssuedOnline || ''});
      toShow.push({'Policy Number': policyNo || ''});
      toShow.push({'Posp Name': pospName || ''});
      toShow.push({'Premium': premium || ''});
      toShow.push({'Product ID': productId || ''});
      toShow.push({'Proposal Amount': proposalAmount || ''});
      toShow.push({'Proposal Created Date': proposalCreatedDate || ''});
      toShow.push({'Proposal Created Date Epoch': proposalCreatedDateEpoch || ''});
      toShow.push({'Proposal ID': proposalId || ''});
      toShow.push({'Proposal Status': proposalStatus || ''});
      toShow.push({'Sum Insured': sumInsured || ''});
      toShow.push({'Sum Insured in Lakhs': sumInsuredInLakhs || ''});
      toShow.push({'Under Writing': underWriting || ''});
      toShow.push({'Sum Insured': sumInsured || ''});
      toShow.push({'Update Policy Date': updatePolicyDate || ''});
      toShow.push({'User ID': userId || ''});

      data.push(Object.assign({}, ...toShow))
    })

    // policySoldList.forEach(policy => {
    //   let toShow=[];
    //   const insuranceType = policy.ins_type || policy.insuranceType;
    //   toShow.push({'Insurance Type': insuranceType || 'Health'});
    //   toShow.push({'Insurance Type': insuranceType || 'Health'});
    // });


    const param = {
      fileName: 'POLICY_SOLD_LIST',
      title: 'POLICY SOLD List',
      data: data
    }
    return exportCsv(param);
  }
    
  const headCells = [
    { id: 'companyLogo', label: 'Insurer' },
    { id: 'insuranceType', label: 'Insurance Type' },
    { id: 'policyNo', label: 'Policy No' },
    { id: 'dateOfPayment', label: 'Payment Date' },
    { id: 'modeOfSale', label: 'Mode Of Sale' },
    { id: 'pospName', label: 'POSP Name' },
    { id: 'planName', label: 'Plan Name' },
    { id: 'sumInsured', label: 'Sum Insured/ IDV' },
    { id: 'premium', label: 'Premium' }]; 
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <span style={{ float: "left" }}>
              <h4 className={classes.cardTitleWhite}>Policy Sold List</h4>

            </span>
            <span className={headerClass.searchWrapper} style={{ float: "right" }}>
              {showInput && <CustomInput
                id="searchInput"
                formControlProps={{
                  className: classNames(headerClass.margin, headerClass.search)
                }}
                inputProps={{
                  inputProps: {
                    "aria-label": "Search",
                    className: classes.inputSearchType
                  }
                }}
                onChange={(event) => filterList(event)}
              />}
              <Button className={classes.paddingStyle} color="white" aria-label="edit" simple onClick={() => toggleInput(!showInput)}>
                <Search /> {' '}Search
              </Button>

              <Button color="white" className={classes.paddingStyle} aria-label="edit" simple onClick={(event) => exportPosp(event)}>
                <ImportExportIcon /> {' '} Export
              </Button>


              <Button color="white" className={classes.paddingStyle} aria-label="edit" simple >
                <FilterListIcon /> {' '} Filter
              </Button>
            </span>



          </CardHeader>
          <CardBody>

            <PolicySoldTable
              tableHeaderColor="primary"
              headCells={headCells}
              isSelectRequired={false}
              orderByParameter="name"
              orderAs="asc"
              rows={filteredList}
              getDetails={getDetails}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
