import React from "react";
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import CustomInput from 'components/CustomInput/CustomInput';
import CustomDatePicker from 'components/DatePicker/CustomDatePicker';
import Card from '../../../components/Card/Card';
import CardHeader from '../../../components/Card/CardHeader';
import CardFooter from "components/Card/CardFooter.js";
import CardBody from '../../../components/Card/CardBody';
import SelectOption from '../../../components/CustomSelect/SelectOption';
import { renderDatePicker } from 'components/ReduxComponents/reduxComponents';
import { proposalDetails, searchUserByPhoneOrNameWatcher } from "store/actions/productAction";
import {
  Formik, Form, Field, ErrorMessage,
} from 'formik';
import { isEmpty } from 'utils/common';
import * as Yup from 'yup';
import Button from 'components/CustomButtons/Button';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import { Typography } from "@material-ui/core";
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import ProgressBar from '../../../components/ProgressBar/ProgressBar';
import { fetchUsersByPhoneOrNameActionEffect } from "store/sagas/productSaga";


const styles = {
  sectionTitle: {
    fontSize: "1.25rem",
    fontFamily: "Montserrat",
    fontWeight: "500",
    lineHeight: "1.6",
    textTransform: "uppercase"
  },
  buttonCenter: {
    padding: "25px",
    textAlign: "center"
  },
  customFileUpload: {
    border: "1px solid #ccc",
    display: "inline-block",
    padding: "6px 12px",
    cursor: "pointer",
    boxShadow: "0 2px 2px 0 rgba(156, 39, 176, 0.14), 0 3px 1px -2px rgba(156, 39, 176, 0.2), 0 1px 5px 0 rgba(156, 39, 176, 0.12)",
    backgroundColor: "#9c27b0",
    borderRadius: "30px",
    border: "none",
    cursor: "pointer",
    padding: "12px 30px",
    position: "relative",
    fontSize: "12px",
    minWidth: "auto",
    minHeight: "auto",
    textAlign: "center",
    transition: "box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1)",
    fontWeight: "400",
    lineHeight: "1.42857143",
    whiteSpace: "nowrap",
    willChange: "box-shadow, transform",
    textTransform: "uppercase",
    verticalAlign: "middle",
    color: "white",
    fontFamily: "Montserrat",
    width: "200px"
  },
  MuiInputLabelFormControl: {
    color: "#3C4858 !important",
    fontSize: "12px",
    fontFamily: "Montserrat",
    fontWeight: "200",
    lineHeight: "1.42857"
  }
}


const useStyles = makeStyles(styles);
const SpecialFormRedux = props => {
  const classes = useStyles();

  const { handleSubmit, pristine, reset,
    submitting, fromUpdate = false, maritalStatusList, userRolesList, bqpUsersList, searchUsersList, selectedBQP, selectedRefUser, 
    getUploadedFile, fileUploadHandler, searchBQPParams, searchUsersParams, initialValues={}, percent, selectedPAN,
    selectedAadhaarFront, selectedAadhaarBack, selectedCheque, selectedCertificate} = props

  const [roleOptions, setRoleOptions] = React.useState([]);
  React.useEffect(() => {
    if (!isEmpty(userRolesList)) {
      const tempList = [];
      userRolesList.forEach(userRole => {
        if(userRole.role !== 'POSP')
        tempList.push({ value: userRole, label: userRole.role })});
        setRoleOptions(tempList);
      }
  }, [userRolesList])

  const [bqpOptions, allBQPOptions] = React.useState([]);
  React.useEffect(() => {
    if (!isEmpty(bqpUsersList)) {
      const tempList = [];
      bqpUsersList.forEach(user => tempList.push(
        { id: user._id, label: user.phone +' - '+ user.fullName}));
        allBQPOptions(tempList);
    }
  }, [bqpUsersList])

  const [usersOptions, allUsersList] = React.useState([]);
  React.useEffect(() => {
    if (!isEmpty(searchUsersList)) {
      const tempList = [];
      searchUsersList.forEach(user => tempList.push(
        { id: user._id, label: user.phone +' - '+ user.fullName}));
        allUsersList(tempList);
    }
  }, [searchUsersList])

  let yearOptions = [];

  const { isEdit = true } = props;
  const highestQualification = [{ value: 'High School', label: 'High School' },
  { value: 'Secondary School', label: 'Secondary School' },
  { value: 'Graduate', label: 'Graduate' },
  { value: 'Post Graduate', label: 'Post Graduate' },
  { value: 'None of the above', label: 'None of the above' }];

  const validationSchema = Yup.object().shape({
    role: Yup.string().required('Required'),
    fullName: Yup.string().required('Required').matches(/^[a-zA-Z ]*$/, "Must contain only characters"),
    phone: Yup.string().required('Required').length(10, 'Phone number must have 10 digits only'),
    email: Yup.string().matches(/^\s*(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/, 'Must be a Valid Email'),
    alternatePhone: Yup.string().length(10, 'Phone number must have 10 digits only'),
    aadharNo: Yup.string().length(12, 'Aadhaar Number must have 12 digits'),
    city: Yup.string().matches(/^[a-zA-Z ]*$/, "Must contain only characters"),
    state: Yup.string().matches(/^[a-zA-Z ]*$/, "Must contain only characters"),
    country: Yup.string().matches(/^[a-zA-Z ]*$/, "Must contain only characters"),
    // linkBqp: Yup.string().required('Required'),
    panNo: Yup.string().matches(/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/, 'Invalid PAN number (Format - XXXXX1111X)'),
    accountHolderName: Yup.string().matches(/^[a-zA-Z]+$/, "Must contain only characters"),
    bankAccountNumber: Yup.string().matches(/^[0-9]{9,18}$/, 'Must contain digit between 9 to 18'),
    ifsc: Yup.string().matches(/^[A-Za-z]{4}\d{6}$/, "Invalid IFSC code (Format - XXXX111111)"),
    pinCode: Yup.string().matches(/^[0-9]*$/, "Must contain only numbers")
  });

  const genderOptions = [{ value: 'MALE', label: 'MALE' },
  { value: 'FEMALE', label: 'FEMALE' },
  { value: 'OTHERS', label: 'OTHERS' }];

  const martialOptions = [{ value: 'SINGLE', label: 'SINGLE' },
  { value: 'MARRIED', label: 'MARRIED' },
  { value: 'OTHERS', label: 'OTHERS' }];

  let extraInformation = false;
  let dateOfBirth = null;

  const getYears = (dateOfBirth) => {
    yearOptions = [];
    let _dateOfDay = moment().format('YYYY');
    if(dateOfBirth){
      const dob = moment(dateOfBirth).format('YYYY');
      let years = moment().format('YYYY');
      let today = moment(dob).format('YYYY');
      let startyear = parseInt(today) + 14;
      for(let year=parseInt(_dateOfDay); year>startyear; year--){
          yearOptions.push({label: year, value: year});
      }
    }else{
      let today = moment().format('YYYY');
      let startyear = parseInt(_dateOfDay) - 100;
      for(let year=parseInt(_dateOfDay); year>startyear; year--){
          yearOptions.push({label: year, value: year});
      }
    }
  }

  getYears();

  const renderSelect = (props) => {
    const { name = '', label = '', options = [],
      touched = {}, errors = {}, handleChange, values = {}, handleBlur, isRequired } = props
      if(name !== '' && name === 'role'){
        extraInformation = values[name].role === 'BQP' || values[name].role === 'SP' ? false : true;
      }
    return (
      <SelectOption
        name={name}
        labelText={label}
        selectOptions={options}
        helperText={touched[name] && errors[name]}
        error={touched[name] && errors[name]}
        formControlProps={{
          fullWidth: true,
          disabled: !isEdit
        }}
        isRequired={isRequired}
        onSelectChange={handleChange(name)}
        inputProps={{
          value: values[name],
          onBlur: handleBlur(name),
          error: touched[name] && errors[name]
        }}
      />
    )
  }

  const renderInput = (props) => {
    dateOfBirth = props.values.dob;
    const { name = '', label = '', touched = {},
      errors = {}, handleChange, handleBlur, values = {}, isRequired, ...others } = props

    if(props.values.dob){
      getYears(props.values.dob);
    }
    return (
      <CustomInput
        labelText={label}
        name={name}
        formControlProps={{
          fullWidth: true
        }}
        isRequired={isRequired}
        error={touched[name] && errors[name]}
        helperText={touched[name] && errors[name]}
        onChange={handleChange(name)}
        inputProps={{
          value: values[name],
          onBlur: handleBlur(name),
          error: touched[name] && errors[name],
          ...others

        }}

      />
    );
  }
  let today = new Date();
  const maxDob =  new Date(today.getFullYear()-18, today.getMonth(), today.getDate());
  const minDob = new Date(today.getFullYear()-70, today.getMonth(), today.getDate());

  return (
    // <form  >
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema} >
      {(props) => {
        const {
          values,
          touched,
          errors,
          dirty,
          isSubmitting,
          isValid,
          handleChange,
          handleBlur,
          handleSubmit,
          handleReset,
        } = props;

        return (
          <form onSubmit={handleSubmit}>
            {/* <GridItem xs={12} md={12} sm={12}>
              <h4 className={classes.sectionTitle} align="center">Profile Complition</h4>
            </GridItem>
            <div className={classes.root}>
              <ProgressBar color="secondary" percent={percent}/>
            </div> */}
            <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
            <Card>
            <CardHeader>
              <GridItem xs={12} sm={12} md={12}>
                <Typography variant="h6" align="left">Basic Details</Typography>
              </GridItem>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={6} sm={12} md={6}>
                  {renderSelect({
                    name: "role", label: "Role", options: roleOptions, touched, errors,
                    handleChange, values, handleBlur, isRequired: true
                  })}
                </GridItem>

                <GridItem xs={6} sm={12} md={6}>
                  {
                    renderInput({
                      label: "Name", name: "fullName",
                      isRequired: true, touched, errors, handleChange, handleBlur, values,
                      disabled: !isEdit
                    })
                  }

                </GridItem>

                <GridItem xs={12} sm={6} md={6}>
                  <Field name="dob" component={renderDatePicker} format="dd/MM/yyyy" maxDate={maxDob} minDate={minDob} label="Date of Birth" />
                </GridItem>

                <GridItem xs={12} sm={6} md={6}>
                  {
                    renderInput({
                      label: "Phone Number", name: "phone",
                      isRequired: true, touched, errors, handleChange, handleBlur, values, type: 'number',
                      disabled: fromUpdate
                    })
                  }
                </GridItem>

                <GridItem xs={12} sm={6} md={6}>
                  
                  {
                    renderInput({
                      label: "Email Id", name: "email",
                      isRequired: false, touched, errors, handleChange, handleBlur, values,
                      disabled: !isEdit
                    })
                  }

                </GridItem>

                <GridItem xs={12} sm={6} md={6}>
                  {
                    renderSelect({
                      name: "gender", label: "Gender", touched, errors,
                      handleChange, values, handleBlur, options: genderOptions
                    })
                  }

                </GridItem>

                <GridItem xs={12} sm={6} md={6}>
                  {
                    renderSelect({
                      name: "maritalStatus", label: "Marital Status", touched, errors,
                      handleChange, values, handleBlur, options: martialOptions, isRequired: false
                    })
                  }
                </GridItem>

                <GridItem xs={12} sm={6} md={6}>

                  {
                    renderInput({
                      label: "Alternate Phone Number", name: "alternatePhone",
                      isRequired: false, touched, errors, handleChange, handleBlur, values, type: 'number',
                      disabled: !isEdit
                    })
                  }
                </GridItem>

                {extraInformation?<GridItem xs={12} sm={6} md={6}>
                  <Autocomplete 
                    options={bqpOptions}
                    onChange={selectedBQP}
                    getOptionSelected={(option, value) => option.id === value.id}
                    getOptionLabel={option => option.label}
                    onInputChange = {searchBQPParams}
                    noOptionsText = ''
                    renderInput={params => (
                      <TextField
                          name="linkBqp"
                          onBlur={handleBlur('linkBqp')}
                          {...params}
                          label="Link to BQP/SP"
                          margin="normal"
                          placeholder="Start typing..."
                          required={extraInformation}
                          onChange={handleChange('linkBqp')}
                          error={touched['linkBqp'] && errors['linkBqp']}
                          helperText={touched['linkBqp'] && errors['linkBqp']}
                          fullWidth/>
                        )}
                  />
                </GridItem>: null}

                {extraInformation?<GridItem xs={12} sm={6} md={6}>
                  <Autocomplete 
                    options={usersOptions}
                    onChange={selectedRefUser}
                    getOptionSelected={(option, value) => option.id === value.id}
                    getOptionLabel={option => option.label}
                    onInputChange = {searchUsersParams}
                    noOptionsText = ''
                    renderInput={params => (
                      <TextField
                          {...params}
                          label="Referred by"
                          placeholder="Start typing..."
                          margin="normal"
                          fullWidth/>
                        )}
                  />
                </GridItem>: null}

                {
                  [{ label: 'Aadhaar Number', name: 'aadharNo', type: 'number' },
                  { label: 'PAN Number', name: 'panNo' }].map(val => (
                    <GridItem xs={12} sm={6} md={6}>
                      {
                        renderInput({
                          label: val.label, name: val.name,
                          isRequired: false, touched, errors, handleChange, handleBlur, values,
                          disabled: !isEdit, type: val.type || ''
                        })
                      }
                    </GridItem>
                  ))
                }

                {
                  [{ label: 'Address Line', name: 'addressLine1', isRequired: false },
                  ].map(val => (
                    <GridItem xs={12} sm={12} md={12}>

                      {
                        renderInput({
                          label: val.label, name: val.name,
                          isRequired: val.isRequired, touched, errors, handleChange, handleBlur, values,
                          disabled: !isEdit
                        })
                      }

                    </GridItem>
                  ))
                }

                {
                  [
                    { label: 'City', name: 'city', isRequired: false },
                    { label: 'District', name: 'district', isRequired: false },
                    { label: 'State', name: 'state', isRequired: false },
                    { label: 'Country', name: 'country', isRequired: false, value: 'India'},
                    { label: 'Pincode', name: 'pinCode', isRequired: false, type: 'number' },
                    // { label: 'Landmark', name: 'landmark', isRequired: false }
                  ].map(val => (
                    <GridItem xs={12} sm={12} md={3}>
                      {
                        renderInput({
                          label: val.label, name: val.name, value: val.value,
                          isRequired: val.isRequired, touched, errors, handleChange, handleBlur, values,
                          disabled: !isEdit, type: val.type || ''
                        })
                      }
                    </GridItem>
                  ))
                }
            
            <GridItem xs={12} md={12} sm={12}>
              {extraInformation?<GridContainer>
                  <GridItem xs={12} md={12} sm={12}>
                    <h4 className={classes.sectionTitle} align="left">Educational Qualification</h4>
                  </GridItem>
                  <GridItem xs={12} md={6} sm={6}>
                      {renderSelect({
                        name: "qualification", label: "Highest Qualification", options: highestQualification, touched, errors,
                        handleChange, values, handleBlur
                      })}
                  </GridItem>
                  <GridItem xs={12} md={6} sm={6}>
                    {renderSelect({
                      name: "yearOfCompletion", label: "Year of Completion", options: yearOptions, touched, errors,
                      handleChange, values, handleBlur
                    })}
                  </GridItem>       
              </GridContainer>:null}
              </GridItem>       


              <GridItem xs={12} md={12} sm={12}>
              {extraInformation?
              <GridContainer>
                  <GridItem xs={12} sm={12}>
                    <h4 className={classes.sectionTitle} align="left">Bank Details</h4>
                  </GridItem>  
          
                  <GridItem xs={12} sm={6}>
                    {
                      renderInput({
                        label: "Name of Account Holder", name: "accountHolderName",
                        isRequired: false, touched, errors, handleChange, handleBlur, values, type: 'text',
                        disabled: !isEdit
                      })
                    }
                    </GridItem>
                      <GridItem xs={12} sm={6}>
                      {
                        renderInput({
                          label: "Bank Account Number", name: "bankAccountNumber",
                          isRequired: false, touched, errors, handleChange, handleBlur, values, type: 'number',
                          disabled: !isEdit
                        })
                      }
                      </GridItem>
                      <GridItem xs={12} sm={6}>
                      {
                        renderInput({
                          label: "IFSC Code", name: "ifsc",
                          isRequired: false, touched, errors, handleChange, handleBlur, values, type: 'text',
                          disabled: !isEdit
                        })
                      }
                  </GridItem>  
              </GridContainer>:null}
              </GridItem>  
      
              <GridItem xs={12} md={12} sm={12}>
              <div className={classes.buttonCenter}>
                  <Button type="submit" color="primary">Submit</Button>
              </div>
              </GridItem >

            </GridContainer>
            </CardBody>
            </Card>
            </GridItem>
            </GridContainer>
              
            {extraInformation?<GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader>
                  <GridItem xs={12} sm={12} md={12}>
                    <Typography variant="h6" align="let">Upload Documents</Typography>
                  </GridItem>
                </CardHeader>
                <CardBody style={{ textAlign: "center" }}>
                  <GridContainer style={{ textAlign: "center" }} justify="space-between" alignItems="center" direction="row">
                    <GridItem xs={12} sm={12} md={3}>
                        <label className={classes.customFileUpload} htmlFor="file">
                            <input onChange={(e) => {getUploadedFile(e, 'identity', 'pan', 'personalInfo')}} id="file"  type="file" style={{display: 'none'}}/>
                            {selectedPAN==''?<span>Select PAN</span>: selectedPAN}
                        </label>
                          <Button disabled={!selectedPAN} round color="primary" className={classes.buttonMargin} onClick={fileUploadHandler}> Upload</Button>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                        <label className={classes.customFileUpload}>
                            <input onChange={(e) => {getUploadedFile(e, 'addressproof', 'aadhaarFront', 'personalInfo')}}  type="file" style={{display: 'none'}}/>
                            {selectedAadhaarFront==''?<span>Select aadhaar (Front)</span>: selectedAadhaarFront}
                        </label>
                          <Button disabled={!selectedAadhaarFront}  round color="primary" className={classes.buttonMargin} onClick={fileUploadHandler}> Upload</Button>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                        <label className={classes.customFileUpload}>
                            <input onChange={(e) => {getUploadedFile(e, 'addressproof', 'aadhaarBack', 'personalInfo')}}  type="file" style={{display: 'none'}}/>
                            {selectedAadhaarBack==''?<span>Select aadhaar (Back)</span>: selectedAadhaarBack}
                        </label>
                          <Button disabled={!selectedAadhaarBack}  round color="primary" className={classes.buttonMargin} onClick={fileUploadHandler}> Upload</Button>
                    </GridItem>
                  </GridContainer>
                  </CardBody>
                </Card>
                  <Card>
                  <CardHeader>
                    <GridItem xs={12} sm={12} md={12}>
                      <Typography variant="h6" align="left">Educational Document</Typography>
                    </GridItem>
                    </CardHeader>
                    <GridContainer style={{ textAlign: "center" }} justify="space-between" alignItems="center" direction="row">
                    <CardBody>
                    <GridItem xs={12} sm={12} md={12}>
                        <label className={classes.customFileUpload}>
                            <input onChange={(e) => {getUploadedFile(e, 'certificates', 'degree_certificate', 'educationInfo')}}  type="file" style={{display: 'none'}}/>
                            {selectedCertificate==''?<span>Select education certificate</span>: selectedCertificate}
                        </label>
                          <Button disabled={!selectedCertificate} round color="primary" className={classes.buttonMargin} onClick={fileUploadHandler}> Upload</Button>
                    </GridItem>
                    </CardBody>
                  </GridContainer>
                  </Card> 
                  <Card>
                  <CardHeader>
                  <GridItem xs={12} sm={12} md={12}>
                    <Typography variant="h6" align="left">Bank Document</Typography>
                  </GridItem>
                  </CardHeader>
                  <GridContainer style={{ textAlign: "center" }} justify="space-between" alignItems="center" direction="row">
                  <CardBody>
                    <GridItem xs={12} sm={12} md={12}>
                        <label className={classes.customFileUpload}>
                            <input onChange={(e) => {getUploadedFile(e, 'bankdoc', 'chequeleaf_front', 'bankInfo')}}  type="file" style={{display: 'none'}}/>
                            {selectedCheque==''?<span>Select cancelled cheque</span>: selectedCheque}
                        </label>
                          <Button disabled={!selectedCheque} round color="primary" className={classes.buttonMargin} onClick={fileUploadHandler}> Upload</Button>
                    </GridItem>
                  </CardBody>
                  </GridContainer>
                  </Card>
              </GridItem>
            </GridContainer>:null}
          </form>
        )
      }}
    </Formik>

  );
}
export default SpecialFormRedux;