import React from 'react'
import styles from '../../assets/jss/componentsStyles/customDatePickerStyle'
import { makeStyles } from '@material-ui/core/styles'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers'
import classNames from 'classnames'

const useStyles = makeStyles(styles)
export default function CustomDatePicker (props) {
  const { id, label, value, onChange, disabled = false, rest = {}, isRequired, inputProps={} } = props
  const classes = useStyles()
  const { error } = rest

  const datePickerStyle = classNames({
    [classes.muipickerTextBoxError]: error,
    [classes.muipickerTextBox]: !error

  })

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        margin="normal"
        id={id}
        autoOk
        label={label}
        // format="dd/MM/yyyy"
        value={value}
        onChange={onChange}
        className={datePickerStyle}
        fullWidth={true}
        disabled={disabled}
        required={isRequired}
        {...inputProps}
        KeyboardButtonProps={{
          'aria-label': 'change date'
        }}
        {...rest}
      />
    </MuiPickersUtilsProvider>
  )
}
