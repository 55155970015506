import React from "react";

import { Grid, Tabs, Tab, AppBar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import {
  DriveEtaOutlined,
  FavoriteBorderOutlined,
  BeachAccessOutlined,
} from "@material-ui/icons";

import Admin from "../DashBoard/Admin";
import DBChart from "./DBChart";

import styles from "./DashboardAnalyticsStyle";
import { Typography } from "@material-ui/core";

const baseURL = "https://charts.mongodb.com/charts-montotest-zlsry";

const mStatChart = [
  {
    baseURL,
    id: "713fdc0e-a008-4735-a2ee-f01d93f77b33",
    name: "# Users Onboarded",
    filters: null,
    isTimeFilter: false,
  },
  {
    baseURL,
    id: "7358a232-3a40-4d15-b1ce-e619e307426e",
    name: "# Policies Issued",
    filters: null,
    isTimeFilter: false,
  },
  {
    baseURL,
    id: "9dabc0dc-7b32-4cde-a8cf-473db405ef1b",
    name: "Premium Collected",
    filters: null,
    isTimeFilter: false,
  },
  {
    baseURL,
    id: "25c04776-9eed-4cf2-9b82-851f67f7d4f6",
    name: "# Quotes Generated",
    filters: null,
    isTimeFilter: false,
  },
];

const mProjectionChart = [
  {
    baseURL,
    id: "a75d231f-fa96-493b-b469-427090d71738",
    name: "Premium Collected & Policy Issued",
    filters: null,
    isTimeFilter: false,
  },
  {
    baseURL,
    id: "0fe95aa7-ff84-4d32-a734-0ed7020ac23b",
    name: "Premium Collected | Annual",
    filters: null,
    isTimeFilter: false,
  },
  {
    baseURL,
    id: "e14324e4-2ea3-4773-9085-52e4e35b6da5",
    name: "# Policies Issued | Annual",
    filters: null,
    isTimeFilter: false,
  },
];

const mongoCharts = [
  {
    baseURL,
    id: "d9563f82-de2d-4020-b2a0-7e6191405a62",
    filters: null,
    isTimeFilter: false,
  },
  {
    baseURL,
    id: "b6238eb6-4abd-4b26-823b-0bfbc30f0065",
    filters: null,
    isTimeFilter: false,
  },
  {
    baseURL,
    id: "513778c5-dfed-487d-9a7e-7ae0f306761d",
    filters: null,
    isTimeFilter: false,
  },
  {
    baseURL,
    id: "f2852efc-8a63-4d47-986d-eb721a3b3332",
    filters: null,
    isTimeFilter: false,
  },
  {
    baseURL,
    id: "58c7ccee-9674-45e4-abb6-31a2f7c27aa5",
    filters: null,
    isTimeFilter: false,
  },
  {
    baseURL,
    id: "066cd940-d3a2-4ba7-b44f-1ad578662976",
    filters: null,
    isTimeFilter: false,
  },
  {
    baseURL,
    id: "ca050975-a9bf-46d5-a5ba-49891cc463f4",
    filters: null,
    isTimeFilter: false,
  },
  {
    baseURL,
    id: "7e3dce26-cdf9-4483-8ac5-92c25c97c67d",
    filters: null,
    isTimeFilter: false,
  },
  {
    baseURL,
    id: "fe662464-2a6d-4b07-b892-f589e5130221",
    filters: null,
    isTimeFilter: false,
  },
  {
    baseURL,
    id: "0132963a-b24e-4356-b8c0-d2747585918a",
    filters: null,
    isTimeFilter: false,
  },
  {
    baseURL,
    id: "fb63aad8-af59-450a-a0e5-23fa1e091fff",
    filters: null,
    isTimeFilter: false,
  },
  {
    baseURL,
    id: "f91f3e39-c462-4ad1-8aa6-7a5c3514b824",
    filters: null,
    isTimeFilter: false,
  },
  {
    baseURL,
    id: "14280c23-f34d-4b57-859e-c00312cdb512",
    filters: null,
    isTimeFilter: false,
  },
  {
    baseURL,
    id: "4bbd612d-c56c-462b-8315-7b45867fbf69",
    filters: null,
    isTimeFilter: false,
  },
  {
    baseURL,
    id: "a50617b8-23c8-4b70-a51f-d19cbf35c1be",
    filters: null,
    isTimeFilter: false,
  },
  {
    baseURL,
    id: "15433fe2-0c67-4af6-9a8f-fca79cb8553d",
    filters: null,
    isTimeFilter: false,
  },
  {
    baseURL,
    id: "2d59c59e-6752-4b8e-a29f-797cfccd9774",
    filters: null,
    isTimeFilter: false,
  },
  {
    baseURL,
    id: "6c13bb01-7b48-462d-8544-30f03253b88d",
    filters: null,
    isTimeFilter: false,
  },
  {
    baseURL,
    id: "b0ca0140-9704-47ba-821b-bf0947d53c67",
    filters: null,
    isTimeFilter: false,
  },
  {
    baseURL,
    id: "8a700baa-0bef-4272-be0d-581a7cbff0ae",
    filters: null,
    isTimeFilter: false,
  },
];

const mStat2 = [
  {
    baseURL,
    id: "8b57bbec-2652-4a54-aa97-12953ab43fdc",
    filters: null,
    isTimeFilter: false,
  },
  {
    baseURL,
    id: "8e6607d5-15a5-481c-93af-9f1c53e29d3c",
    filters: null,
    isTimeFilter: false,
  },
  {
    baseURL,
    id: "7f0d64c2-d4ff-49ac-8651-c7dff2fc6d7d",
    filters: null,
    isTimeFilter: false,
  },
];

const DashboardAnalyticsPage = () => {
  const classes = styles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Admin>
      <AppBar position="static" color="default" className={classes.appBar}>
        <Tabs
          value={value}
          onChange={handleChange}
          scrollButtons="on"
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab label="Motor" icon={<DriveEtaOutlined />} />
          <Tab label="Health" icon={<FavoriteBorderOutlined />} />
          <Tab label="Term" icon={<BeachAccessOutlined />} />
        </Tabs>
      </AppBar>
      <div role="tabpanel" hidden={value !== 0}>
        <Grid
          container
          justify="flex-start"
          direction="row"
          alignItems="center"
          spacing={1}
        >
          {mStatChart.map(({ baseURL, id, filters, isTimeFilter }) => (
            <Grid item xs={12} md={3} style={{ marginBottom: 15 }} key={id}>
              <DBChart
                chartBaseURL={baseURL}
                chartID={id}
                filters={filters}
                height={180}
                isTimeFilter={isTimeFilter}
              />
            </Grid>
          ))}
          {mProjectionChart.map(({ baseURL, id, filters, isTimeFilter }) => (
            <Grid item xs={12} md={4} style={{ marginBottom: 15 }} key={id}>
              <DBChart
                chartBaseURL={baseURL}
                chartID={id}
                filters={filters}
                isTimeFilter={isTimeFilter}
              />
            </Grid>
          ))}
          <Grid item xs={12} md={3}>
            <Typography align="center" style={{ marginBottom: 15 }}>
              Overall
            </Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography align="center" style={{ marginBottom: 15 }}>
              Insurer Basis
            </Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography align="center" style={{ marginBottom: 15 }}>
              Vehicle Insurance Type
            </Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography align="center" style={{ marginBottom: 15 }}>
              Policy Type
            </Typography>
          </Grid>
          {mongoCharts.map(({ baseURL, id, filters, isTimeFilter }) => (
            <Grid item xs={12} md={3} style={{ marginBottom: 15 }} key={id}>
              <DBChart
                chartBaseURL={baseURL}
                chartID={id}
                filters={filters}
                isTimeFilter={isTimeFilter}
              />
            </Grid>
          ))}
          {mStat2.map(({ baseURL, id, filters, isTimeFilter }) => (
            <Grid item xs={12} md={4} style={{ marginBottom: 15 }} key={id}>
              <DBChart
                chartBaseURL={baseURL}
                chartID={id}
                filters={filters}
                isTimeFilter={isTimeFilter}
              />
            </Grid>
          ))}
        </Grid>
      </div>
      <div role="tablabel" hidden={value != 1}>
        <Alert severity="info">
          Health Analytics will be displayed shortly
        </Alert>
      </div>
      <div role="tablabel" hidden={value != 2}>
        <Alert severity="info">Term Analytics will be displayed shortly</Alert>
      </div>
    </Admin>
  );
};

export default DashboardAnalyticsPage;
