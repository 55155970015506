//Login
export const LOGIN = "login";

// APP ROOT
export const APP_ROOT = "dashboard";

//POS list
export const POS_LIST = "pos-list";

//POS Detail
export const POS_DETAIL = "pos-detail";

//POS Approval
export const POS_APPROVAL = "pos-approval";

//POS Eligibility check
export const POS_ELIGIBILITY_CHECK = "pos-eligibility";

//POS Onboarding List
export const POS_ONBOARDING = "pos-onboarding";

//POLICY SOLD
export const POLICY_SOLD = "policy-sold";

//POLICY SOLD DETAILS
export const POLICY_DETAILS = "policy-details";

//DSA LIST
export const DSA_LIST = "dsa-list";

//DSA-DETAILS
export const DSA_DETAIL = "dsa-detail";

//ADD-DSA
export const ADD_DSA = "dsa-add";

//Offline Proposal-create
export const OFFLINE_PROPOSAL = "new-proposal";

//Offline-proposal list
export const OFFLINE_PROPOSAL_LIST = "offline-proposals";

//globalCommission list
export const GLOBAL_COMMISSION = "global-commission";

export const GLOBAL_CHAIN_COMMISSION = "global-chain-commission";

//fixed payout page
export const FIXED_PAYOUT = "fixed-payout";

//Generate OTP page
export const GENERATE_OTP = "generate-otp";

//Global Payout Frequency Page
export const PAYOUT_FREQUENCY = "payout-frequency";

//Special User create
export const CREATE_SPECIAL_USER = "special-user-create";

//Special User List
export const SPECIAL_USER = "specialUsers";

//Special User Detail
export const SPECIAL_USER_DETAIL = "specialUser";

export const NOTIFICATION = "notification";

export const DASHBOARD_ANALYTICS = "analytics";
