import * as actions from './types';

export function fetchSpecialUserList() {
    return { type: actions.SPECIAL_USER_LIST_WATCHER };
  }

export function fetchSpecialUser(userId) {
  return {
    type: actions.SPECIAL_USER_DETAIL_WATCHER,
    payload: userId,
  };
}

export function fetchAssistedSales(userId) {
  return {
    type: actions.FETCH_ASSISTED_SALES_WATCHER,
    payload: userId,
  };
}

export function createSpecialUserWatcher(param, resolve, reject) {
  return ({
    type: actions.CREATE_SPECIAL_USER_WATCHER,
    payload: param, resolve, reject
  });
}

export function updateSpecialUserWatcher(param, resolve, reject) {
  return ({
    type: actions.UPDATE_SPECIAL_USER_WATCHER,
    payload: param, resolve, reject
  });
}

export function specialUserList(specialUserList) {
    return { type: actions.SPECIAL_USER_LIST, specialUserList }
  }
  
  export function specialUserDetail(specialUserDetail) {
    return { type: actions.SPECIAL_USER_DETAIL, specialUserDetail }
  }

  export function assistedSales(assistedSales) {
    return {type: actions.ASSISTED_SALES, assistedSales}
  }

  export function userRolesList(userRolesList) {
    return { type: actions.USER_ROLES_LIST, userRolesList }
  }