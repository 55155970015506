import React, { useEffect, useRef, useState } from "react";

import {
  Dialog,
  DialogActions,
  Card,
  DialogContent,
  CardContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Button,
  useMediaQuery,
  useTheme,
  IconButton,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { Refresh, ZoomOut, CalendarToday } from "@material-ui/icons";
import ChartsEmbedSDK from "@mongodb-js/charts-embed-dom";

import styles from "./DashboardAnalyticsStyle";

const sdk = new ChartsEmbedSDK({
  baseUrl: "https://charts.mongodb.com/charts-montotest-zlsry",
});

const ChartDialog = ({ chartID, isPopupOpen, handleClose }) => {
  const classes = styles();
  let chartRef = useRef(null);
  const [chart, setChart] = useState(null);
  const [isMount, setMount] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const myInterval = setInterval(() => {
    if (chartRef.current) {
      setMount(true);
      clearInterval(myInterval);
    }
  }, 2000);

  useEffect(() => {
    if (isMount) {
      const chart = sdk.createChart({
        chartId: chartID,
      });
      setChart(chart);

      // IIFE
      (async function renderChart() {
        if (chartRef.current) {
          console.log("Loading chart", chartRef.current);
          await chart.render(chartRef.current);
        }
      })();
    }
  }, [isMount]);

  const handleRefresh = () => {
    chart.refresh();
  };

  return (
    <Dialog open={isPopupOpen} onClose={handleClose} fullScreen={fullScreen}>
      <DialogContent>
        <div ref={chartRef} className={classes.dialogContent}></div>
      </DialogContent>
      <DialogActions>
        <IconButton onClick={handleClose} color="primary">
          <ZoomOut fontSize="small" />
        </IconButton>
        <IconButton onClick={handleRefresh} color="primary">
          <Refresh fontSize="small" onClick={handleRefresh} />
        </IconButton>
      </DialogActions>
    </Dialog>
  );
};

export default ChartDialog;
