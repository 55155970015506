import React, { useEffect, useRef, useState, memo } from "react";

import {
  Dialog,
  DialogActions,
  Card,
  DialogContent,
  CardContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Button,
  IconButton,
  CircularProgress,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { Refresh, FilterList, ZoomIn } from "@material-ui/icons";
import ChartsEmbedSDK from "@mongodb-js/charts-embed-dom";

import ChartDialog from "./ChartDialog";

const sdk = new ChartsEmbedSDK({
  baseUrl: "https://charts.mongodb.com/charts-montotest-zlsry",
});

const DBChart = ({
  chartBaseURL,
  chartID,
  filters,
  isTimeFilter,
  height = 360,
}) => {
  const chartRef = useRef();
  const [chart, setChart] = useState(null);
  const [policyType, setPolicyType] = useState("");
  const [policyTime, setPolicyTime] = useState("");
  const [isPopupOpen, setPopupOpen] = useState(false);

  useEffect(() => {
    const chart = sdk.createChart({
      chartId: chartID,
      autoRefresh: true,
    });
    setChart(chart);
    // IIFE
    (async function renderChart() {
      await chart.render(chartRef.current);
      setPolicyType("All");
      setPolicyTime("All");
    })();
  }, []);

  const handleRefresh = () => {
    chart.refresh();
  };

  const handleOpen = () => {
    setPopupOpen(true);
  };

  const handleClose = () => {
    setPopupOpen(false);
  };

  return (
    <div>
      <ChartDialog
        handleClose={handleClose}
        chartID={chartID}
        isPopupOpen={isPopupOpen}
      />
      <Card className="text-center">
        <CardContent style={{ height: height, padding: 0 }} ref={chartRef}>
          <CircularProgress />
        </CardContent>
        <IconButton>
          <ZoomIn onClick={handleOpen} fontSize="small" />
        </IconButton>
        <IconButton>
          <Refresh onClick={handleRefresh} fontSize="small" />
        </IconButton>
      </Card>
    </div>
  );
};

export default DBChart;
