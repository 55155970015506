import { call, put, takeLatest, fork, all } from 'redux-saga/effects'
import * as actions from 'store/actions/types'
import axios from 'utils/axios'
import urls from 'utils/urls';
import { createUrl, isEmpty } from 'utils/common'
import {posPayoutList, policyWiseEarnings} from 'store/actions/payoutAction'
import ErrorIcon from '@material-ui/icons/Error'
import {updateAlertMsg} from 'store/actions/commonAction';

// ################### fetch pos payout ############################################

function fetchPosPayout (params) {
    return axios.request({
      method: 'post',
      url: createUrl(urls.BASE_URL + urls.pos.BASE_URL + urls.pos.PAYOUT),
      headers: {
        'Content-Type': 'application/json'
      },
      data: params
    })
  }
  
  export function * fetchPosPayoutActionEffect (params) {
      const {payload, resolve, reject} = params
    try {
      const { data } = yield call(fetchPosPayout, payload)
  
      if (!isEmpty(data)) {
        console.log('Pos payout fetched')
  
        yield put(posPayoutList(data))
        resolve(data);
      } else {
        yield put(posPayoutList([]))
        const alertMsg = {
          color: 'danger',
          icon: ErrorIcon,
          message: 'No Data Available.'
        }
        yield put(updateAlertMsg(alertMsg));
        reject(data);

      }
    } catch (e) {
      //   alert.error(e.message || 'Authentication Error');
      //   yield put(authError(e));
      console.log('Error found while fetching dsa')
      yield put(posPayoutList([]))
      reject(e);
      // if (reject) reject(e);
    }
  }
  
  export function * fetchPosPayoutActionWatcher () {
    yield takeLatest(actions.FETCH_ALL_PAYOUT_WATCHER, fetchPosPayoutActionEffect)
  }

  //########### Pos Payout update ######################################

  function updatePosPayout(param) {
    const url = createUrl(urls.BASE_URL + urls.pos.BASE_URL + urls.pos.DETAIL) + '/' + param.userId + urls.pos.UPDATE_PAYOUT
    console.log(url)
    const params = { ...param.payout };
    return axios.request({
      method: 'put',
      url: url,
      headers: {
        'Content-Type': 'application/json'
      },
      data: params
    })
  }

  export function * updatePosPayoutActionEffect (params) {
    const {payload, resolve, reject} = params
  try {
    const { data } = yield call(updatePosPayout, payload)

    if (!isEmpty(data)) {
      console.log('Pos payout fetched')
      resolve(data);
    } else {
      reject(data);
    }
  } catch (e) {
    //   alert.error(e.message || 'Authentication Error');
    //   yield put(authError(e));
    console.log('Error found while fetching dsa')
    reject(e);
    // if (reject) reject(e);
  }
}

  export function * updatePosPayoutActionWatcher() {
    yield takeLatest(actions.UPDATE_POS_PAYOUT_WATCHER, updatePosPayoutActionEffect);
  }

  // ######## Get policy wise earnings ########################################

  function fetchPolicyWiseEarnings(params) {
    return axios.request({
      method: 'get',
      url: createUrl(urls.BASE_URL + urls.pos.BASE_URL + urls.pos.DETAIL)+ `/${params.userId}${urls.pos.POLICY_WISE_EARNING}`,
      headers: {
        'Content-Type': 'application/json'
      },
      
    })
  }

  export function * fetchPolicyWiseEarningsActionEffect(params) {
    const {payload} = params;
    try {
      const {data} = yield call(fetchPolicyWiseEarnings, payload);
      if (!isEmpty(data)) {
        console.log('Pos payout fetched')
        yield put(policyWiseEarnings(data));
      } else {
        yield put(policyWiseEarnings([]));
        const alertMsg = {
          color: 'danger',
          icon: ErrorIcon,
          message: 'No Policy Wise earning available for this pos.'
        }
        yield put(updateAlertMsg(alertMsg));
      }
    }catch (e) {

      console.log('Error found while fetching dsa')
      yield put(policyWiseEarnings([]))
      const alertMsg = {
        color: 'danger',
        icon: ErrorIcon,
        message: 'No Policy Wise earning available for this pos.'
      }
      yield put(updateAlertMsg(alertMsg));
      // if (reject) reject(e);
    }
  }

  export function * fetchPolicyWiseEarningsActionWatcher(){
    yield takeLatest(actions.FETCH_POLICYWISE_EARNINGS_WATCHER,  fetchPolicyWiseEarningsActionEffect);
  }

  export default function * rootSaga() {
    yield all([
      fork(fetchPosPayoutActionWatcher),
      fork(updatePosPayoutActionWatcher),
      fork(fetchPolicyWiseEarningsActionWatcher)
    ])
  }
