
import config from '../config';
// export const proxy = "https://develop.iceinsurance.in";
console.log('configs', config);
export const proxy = config.api.URL
// eslint-disable-next-line no-useless-escape
export const emailCheckRegex = /^\s*(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/

export const phoneNumberRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/

export const aadharNumberRegex = /^[0-9]{12}$/

export const panNoRegex = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/

export const bankNoRegex = /^[0-9]{9,18}$/

export const alphaNumericRegex = /[a-zA-Z0-9]/
export const charRegex = /^([a-zA-Z]+\s)*[a-zA-Z]+$/

export const dateCheckRegex = /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/

export const pinCodeRegex = /^[1-9][0-9]{5}$/

export const imageUrlRegex = /(http(s?):)([/|.|\w|\s|-])*\.(?:jpg|gif|png|jpeg)/g
export const alphaNumericWithUnderHypenRegex = /^[a-zA-Z\d-_]+$/;

export const APPROVAL_STATUS = {
  REJECTED: 'REJECTED',
  APPROVED: 'APPROVED'
}

export const ELIGIBILITY_STATUS = {
  REJECTED: 'REJECTED',
  ACCEPTED: 'ACCEPTED'
}

export const ONBOARD_STATUS = {
  REJECTED: 'REJECTED',
  ONBOARDED: 'ONBOARDED',
  APPROVED: 'APPROVED'
}

export const CompanyMapper = new Map([[3, 'ICICI'],
[4, 'Religare'],
[5, 'Apollo'],
[6, 'HDFC Ergo'],
[7, 'Reliance'],
[8, 'Kotak'],
[9, 'Star'],
[10, 'Bajaj Allianz'],
[11, 'AdityaB'],
[12, 'Digit'],
[13, 'Future Generali'],
[14, 'Royal Sundaram General Insurance Co. Ltd'],
[15, 'ICICI Lombard General Insurance Co. Ltd.'],
[16, 'Liberty General Insurance Co. Ltd'],
[17, 'Edelweiss General Insurance'],
[18, 'SBI General Insurance'],
[19, 'Bharati AXA'],
[20, 'Sriram Capital'],
[21, 'TATA AIG']
])

export const CompanyReverseMapper = new Map([['ICICI', 3],
['Religare', 4],
['Apollo', 5],
['HDFC Ergo', 6],
['Reliance', 7],
['Kotak', 8],
['Star', 9],
['Bajaj', 10],
['AdityaB', 11],
['Digit', 12],
['Future Generali', 13],
['Royal Sundaram General Insurance Co. Ltd', 14],
['ICICI Lombard General Insurance Co. Ltd.', 15],
['Liberty General Insurance Co. Ltd', 16],
['Edelweiss General Insurance', 17],
['SBI General Insurance', 18],
['Bharati AXA', 19],
['Sriram Capital', 20],
['TATA AIG', 21]
])

export const InsTypeMapper = new Map([
  ['health', 'Health'],
  ['motor_4w', 'Car Insurance'],
  ['motor', 'Car Insurance'],
  ['motor_2w', 'Bike Insurance']
]);

export const DocumentMapper = new Map([['pan_front', 'PAN'],
['aadhar_front', 'Aadhaar - Front'],
['aadhar_back', 'Aadhaar - Back'],
['chequeleaf_front', 'Cheque'],
['selfie', 'Selfie'],
['degree_certificate', 'Degree Certificate']])



export const POSProleMapper = new Map([['srmanager', 'Sr. Manager'],
['dsa', 'DSA'],
['manager', 'Manager'],
['advisor', 'Advisor'],
['posp2', 'POSP2'],
['posp1', 'POSP1']])

export const LevelUnderDSARank = {
  dsa: 1,
  srmanager: 2,
  manager: 3,
  advisor: 4,
  posp2: 5,
  posp1: 6
}

export const fieldIdMapper = new Map([['fullName', 'Full Name'],
['businessEntityName', 'BusinessEntity Name'],
['businessAddress', 'Business Address'],
['email', 'Email Id'],
['phone', 'Phone Number'],
['aadharNo', 'Aadhar Numebr'],
['panNo', 'PAN Number'],
['areaOfOperations', 'Area Of Operations'],
['totalChainCommission', 'Total Chain Commission'],
['incentives', 'Incentives'],
['frequencyOfPayments', 'Frequency Of Payments'],
['paymentMode', 'Payment Mode'],
['userId', 'User'],
['insuranceType', 'Insurance Type'],
['companyId', 'Insurance Company'],
['productId', 'Product'],
['tenure', 'Tenure'],
['sumInsured', 'Sum Insured'],
['premium', 'Premium'],
['policyNo', 'Policy Number'],
['dateOfPayment', 'Date of Payment'],
['modeOfPayment', 'Mode of Payment'],
['dateOfPolicyIssued', 'Date of Policy Issued'],
['title', 'Title'],
['firstName', 'First Name'],
['lastName', 'Last Name'],
['dob', 'Date of Birth'],
['gender', 'Gender'],
['maritalStatus', 'Marital Status'],
['relation', 'Relationship'],
['heightInFeet', 'Height (in feet)'],
['heightInInches', 'Height (in inches'],
['weight', 'Weight'],
['idProofType', 'Id Proof Type'],
['idProofNumber', 'Id Proof Number'],
['addressLine1', 'Address'],
['addressLine2', 'Address'],
['city', 'City'],
['state', 'State'],
['country', 'Country'],
['pinCode', 'Pincode'],
['amountPaidOn', 'Amount Paid On']
]);

export const FrequencyTypeConst = {
  DAILY: "DAILY",
  WEEKLY: "WEEKLY",
  MONTHLY: "MONTHLY"
};

export const MotorPolicyTypeConst = {
  comprehensive: 'Comprehensive',
  thirdparty: 'Third Party',
  owndamage: 'Own Damage',
  ['1odplus3tp']: '1 OD + 3TP',
  ['1odplus5tp']: '1OD + 5TP',
  ['5tp']: '5TP',
  ['3tp']: '3TP'
};
