import { call, put, takeLatest, all, fork } from 'redux-saga/effects'
import * as actions from '../actions/types'
import axios from '../../utils/axios'
import urls from '../../utils/urls'
import { createUrl, isEmpty } from '../../utils/common'
import {
  posList, posDetail, policySoldList, posOnboardingList,
  fetchposDetailWatcher, fetchposListWatcher, fetchposOnboardingListWatcher, posEarning, rotUsersList
} from '../actions/posAction'
import {toggleBackDrop} from 'store/actions/commonAction';

// ##################### Get all pos list ##########################################################

function fetchPosList () {
  return axios.request({
    method: 'get',
    url: createUrl(urls.BASE_URL + urls.pos.BASE_URL),
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

// Saga function that handles the side effect when the loginActionWatcher is triggered
export function * fetchPosListActionEffect () {
  // let { resolve, reject } = posAction;

  try {
    const { data } = yield call(fetchPosList)

    if (!isEmpty(data)) {
      console.log('POS Data fetched')

      yield put(posList(data))
    } else {
      // reject(data);
    }
  } catch (e) {
    //   alert.error(e.message || 'Authentication Error');
    //   yield put(authError(e));
    console.log('Error found while fetching pos')
    // if (reject) reject(e);
  }
}

// Saga function that is initiated in the beginning to be able to listen to LOG_IN_WATCHER action
export function * fetchPosListActionWatcher () {
  yield takeLatest(actions.POS_LIST_WATCHER, fetchPosListActionEffect)
}

// ##################### Get pos Earning Details ##########################################################

function fetchPosEarning (param) {
  const posId = param.posId
  delete param.posId

  return axios.request({
    method: 'get',
    url: createUrl(urls.BASE_URL + urls.pos.BASE_URL + urls.pos.DETAIL) + `/${posId}${urls.pos.EARNING}`,
    headers: {
      'Content-Type': 'application/json'
    },
    params: !isEmpty(param) ? { ...param } : ''
  })
}

// Saga function that handles the side effect when the loginActionWatcher is triggered
export function * fetchPosEarningActionEffect (param) {
  const { payload, resolve, reject } = param

  try {
    const { data } = yield call(fetchPosEarning, payload)

    if (!isEmpty(data) && !isEmpty(data.userId)) {
      console.log('POS Earning data fetched')

      yield put(posEarning(data))
      resolve(data)
    } else {
      reject(data)
      yield put(posEarning({}))
    }
  } catch (e) {
    yield put(posEarning({}))
    reject(e)
    //   alert.error(e.message || 'Authentication Error');
    //   yield put(authError(e));

    console.log('Error found while fetching pos')
    // if (reject) reject(e);
  }
}

// Saga function that is initiated in the beginning to be able to listen to LOG_IN_WATCHER action
export function * fetchPosEarningActionWatcher () {
  yield takeLatest(actions.FETCH_POSP_EARNING_WATCHER, fetchPosEarningActionEffect)
}

// ##################### Get pos Onboarding list ##########################################################

function fetchPosOnboardingList () {
  return axios.request({
    method: 'get',
    url: createUrl(urls.BASE_URL + urls.pos.BASE_URL + urls.pos.ONBOARDING_LIST),
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

// Saga function that handles the side effect when the loginActionWatcher is triggered
export function * fetchPosOnboardingListEffect () {
  // let { resolve, reject } = posAction;

  try {
    const { data } = yield call(fetchPosOnboardingList)

    if (!isEmpty(data)) {
      console.log('POS Data fetched')

      yield put(posOnboardingList(data))
    } else {
      // reject(data);
    }
  } catch (e) {
    //   alert.error(e.message || 'Authentication Error');
    //   yield put(authError(e));
    console.log('Error found while fetching pos')
    // if (reject) reject(e);
  }
}

// Saga function that is initiated in the beginning to be able to listen to LOG_IN_WATCHER action
export function * fetchPosOnboardingListActionWatcher () {
  yield takeLatest(actions.POS_ONBOARDING_LIST_WATCHER, fetchPosOnboardingListEffect)
}

// ###################### Fetch Pos User Details #################################################

function fetchPosDetail (posId) {
  const url = createUrl(urls.BASE_URL + urls.pos.BASE_URL + urls.pos.DETAIL) + '/' + posId
  console.log(url)
  return axios.request({
    method: 'get',
    url: url,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function * fetchPosDetailActionEffect (posDetails) {
  const { payload } = posDetails
  console.log('payload user id ---->', payload)
  try {
    yield put(toggleBackDrop(true));
    const { data } = yield call(fetchPosDetail, payload)
    yield put(toggleBackDrop(false));
    if (!isEmpty(data)) {
      console.log('POS Data fetched')

      yield put(posDetail(data))
    } else {
      // reject(data);
      yield put(posDetail({}))
    }
  } catch (e) {
    yield put(toggleBackDrop(false));
    console.log('Error found while fetching pos')
    yield put(posDetail({}))
  }
}

export function * fetchPosDetailActionWatcher () {
  yield takeLatest(actions.POS_DETAIL_WATCHER, fetchPosDetailActionEffect)
}

// ##################### Update Pos Education Info ################################################

function updatePosEducationInfo (posDetail) {
  const url = createUrl(urls.BASE_URL + urls.pos.BASE_URL + urls.pos.DETAIL) + '/' + posDetail.posId + urls.pos.EDUCATION_INFO
  console.log(url)
  return axios.request({
    method: 'put',
    url: url,
    headers: {
      'Content-Type': 'application/json'
    },
    data: posDetail
  })
}

export function * updatePosEducationInfoActionEffect (posDetails) {
  const { payload, resolve, reject } = posDetails
  console.log('payload user id ---->', payload)
  try {
    const { data } = yield call(updatePosEducationInfo, payload)
    if (!isEmpty(data)) {
      console.log('POS Data fetched')
      yield put(fetchposDetailWatcher(payload.posId))
      resolve()

      //  yield put(posDetail(data));
    } else {
      reject(data)
    }
  } catch (e) {
    reject(e.data)
    console.log('Error found while fetching pos')
  }
}

export function * updatePosEducationInfoActionWatcher () {
  yield takeLatest(actions.UPDATE_EDUCATION_INFO_WATCHER, updatePosEducationInfoActionEffect)
}
// ############################# Update Pos Bank Info ###############################################################

function updatePosBankInfo (posDetail) {
  const url = createUrl(urls.BASE_URL + urls.pos.BASE_URL + urls.pos.DETAIL) + '/' + posDetail.posId + urls.pos.BANK_INFO
  console.log(url)
  return axios.request({
    method: 'put',
    url: url,
    headers: {
      'Content-Type': 'application/json'
    },
    data: posDetail
  })
}

export function * updatePosBankInfoActionEffect (posDetails) {
  const { payload, resolve, reject } = posDetails
  console.log('payload user id ---->', payload)
  try {
    const { data } = yield call(updatePosBankInfo, payload)
    if (!isEmpty(data)) {
      console.log('POS Data fetched')
      yield put(fetchposDetailWatcher(payload.posId))
      resolve()

      //  yield put(posDetail(data));
    } else {
      reject(data)
    }
  } catch (e) {
    reject(e.data)
    console.log('Error found while fetching pos')
  }
}

export function * updatePosBankInfoActionWatcher () {
  yield takeLatest(actions.UPDATE_BANK_INFO_WATCHER, updatePosBankInfoActionEffect)
}

// ################# Update Pos Personal Info ####################################

function updatePosPersonalInfo (posDetail) {
  const url = createUrl(urls.BASE_URL + urls.pos.BASE_URL + urls.pos.DETAIL) + '/' + posDetail.posId + urls.pos.PERSONAL_INFO
  console.log(url)
  return axios.request({
    method: 'put',
    url: url,
    headers: {
      'Content-Type': 'application/json'
    },
    data: posDetail
  })
}

export function * updatePosPersonalInfoActionEffect (posDetails) {
  const { payload, resolve, reject } = posDetails
  console.log('payload user id ---->', payload)
  try {
    const { data } = yield call(updatePosPersonalInfo, payload)
    if (!isEmpty(data)) {
      console.log('POS Data fetched')
      yield put(fetchposDetailWatcher(payload.posId))
      resolve()
      // yield put(posDetail(data));
    } else {
      reject(data)
    }
  } catch (e) {
    reject(e.data)
    console.log('Error found while fetching pos')
  }
}

export function * updatePosPersonalInfoActionWatcher () {
  yield takeLatest(actions.UPDATE_PERSONAL_INFO_WATCHER, updatePosPersonalInfoActionEffect)
}

// ##################### Update Pos Business Info Action Watcher #########################

function updatePosBusinessInfo (posDetail) {
  const url = createUrl(urls.BASE_URL + urls.pos.BASE_URL + urls.pos.DETAIL) + '/' + posDetail.posId + urls.pos.BUSINESS_INFO
  console.log(url)
  return axios.request({
    method: 'put',
    url: url,
    headers: {
      'Content-Type': 'application/json'
    },
    data: posDetail
  })
}

export function * updatePosBusinessInfoActionEffect (posDetails) {
  const { payload, resolve, reject } = posDetails
  console.log('payload user id ---->', payload)
  try {
    const { data } = yield call(updatePosBusinessInfo, payload)
    if (!isEmpty(data)) {
      console.log('POS Data fetched')
      yield put(fetchposDetailWatcher(payload.posId))
      resolve()
      //  yield put(posDetail(data));
    } else {
      reject(data)
    }
  } catch (e) {
    reject(e.data)
    console.log('Error found while fetching pos')
  }
}

export function * updatePosBusinessInfoActionWatcher () {
  yield takeLatest(actions.UPDATE_BUSINESS_INFO_WATCHER, updatePosBusinessInfoActionEffect)
}
// ############### POS Certification Status Update #################################

function updatePosCertificationInfo (posDetail) {
  const url = createUrl(urls.BASE_URL + urls.pos.BASE_URL + urls.pos.DETAIL) + '/' + posDetail.posId + urls.pos.CERTIFICATION_INFO
  console.log(url)
  return axios.request({
    method: 'put',
    url: url,
    headers: {
      'Content-Type': 'application/json'
    },
    data: posDetail
  })
}

export function * updatePosCertificationInfoActionEffect (posDetails) {
  const { payload, resolve, reject } = posDetails
  payload.isExamPassed = (payload.isExamPassed === 'true')
  try {
    const { data } = yield call(updatePosCertificationInfo, payload)
    if (!isEmpty(data)) {
      console.log('POS Data fetched')
      yield put(fetchposDetailWatcher(payload.posId))
      resolve()
      //  yield put(posDetail(data));
    } else {
      reject(data)
    }
  } catch (e) {
    reject(e.data)
    console.log('Error found while fetching pos')
  }
}

export function * updatePosCertificationInfoActionWatcher () {
  yield takeLatest(actions.UPDATE_CERTIFICATION_INFO_WATCHER, updatePosCertificationInfoActionEffect)
}

// ################ PoS status upload ##################################################

function updatePosApprovalStatus (params) {
  const url = createUrl(urls.BASE_URL + urls.pos.BASE_URL + urls.pos.DETAIL) + '/' + params.posId + urls.pos.UPLOAD_STATUS
  console.log(url)
  return axios.request({
    method: 'put',
    url: url,
    headers: {
      'Content-Type': 'application/json'
    },
    data: params
  })
}

export function * updatePosApprovalStatusActionEffect (params) {
  const { payload, resolve, reject } = params
  console.log('payload user id ---->', payload)
  try {
    const { data } = yield call(updatePosApprovalStatus, payload)
    if (!isEmpty(data)) {
      console.log('Pos status updated successfully')
      yield put(fetchposOnboardingListWatcher())
      resolve(data)
      //  yield put(posDetail(data));
    } else {
      reject(data)
      // reject(data);
    }
  } catch (e) {
    reject(e.data)
    console.log('Error found while fetching pos')
  }
}

export function * updatePosApprovalStatusActionWatcher () {
  yield takeLatest(actions.UPDATE_POS_APPROVAL_STATUS_WATCHER, updatePosApprovalStatusActionEffect)
}

// #################### Fetch Policy Sold #####################################################

function fetchPolicySoldList (param) {
  return axios.request({
    method: 'post',
    url: createUrl(`${urls.BASE_URL}${urls.pos.BASE_URL}${urls.pos.DETAIL}${urls.pos.POLICY_SOLD}`),
    headers: {
      'Content-Type': 'application/json'
    },
    data: param
  })
}

export function * fetchPolicySoldListActionEffect (params) {
  const { payload } = params
  console.log('payload user id ---->', payload)
  try {
    const { data } = yield call(fetchPolicySoldList, payload)
    if (!isEmpty(data)) {
      console.log('Pos status updated successfully')

      yield put(policySoldList(data))

      //  yield put(posDetail(data));
    } else {
      // reject(data);
      yield put(policySoldList({}));
    }
  } catch (e) {
    yield put(policySoldList({}));
    console.log('Error found while fetching pos')
  }
}

export function * fetchPolicySoldListActionWatcher () {
  yield takeLatest(actions.FETCH_POLICY_SOLD_WATCHER, fetchPolicySoldListActionEffect)
}
// ################# POS ELIGIBILITY STATUS UPLOAD ###############################

function updatePosEligibiltyStatus (params) {
  const url = createUrl(urls.BASE_URL + urls.pos.BASE_URL) + '/' + params.posId + urls.pos.UPLOAD_ELIGIBILITY_STATUS
  console.log(url)
  return axios.request({
    method: 'put',
    url: url,
    headers: {
      'Content-Type': 'application/json'
    },
    data: params
  })
}

export function * updatePosEligibiltyStatusActionEffect (params) {
  const { payload, resolve, reject } = params
  console.log('payload user id ---->', payload)
  try {
    const { data } = yield call(updatePosEligibiltyStatus, payload)
    if (!isEmpty(data)) {
      console.log('Pos status updated successfully')
      yield put(fetchposListWatcher())
      resolve(data)
      //  yield put(posDetail(data));
    } else {
      reject(data)
    }
  } catch (e) {
    reject(e.data)
    console.log('Error found while fetching pos')
  }
}

export function * updatePosEligibiltyStatusActionWatcher () {
  yield takeLatest(actions.UPDATE_POS_ELIGIBILITY_STATUS_WATCHER, updatePosEligibiltyStatusActionEffect)
}

// ####################### POS DOCUMENTS FETCH ###################################################

function getDocuments (params) {
  const url = createUrl(urls.BASE_URL + urls.pos.DOWNLOAD_DOCUMENTS)
  console.log(url)
  return axios.request({
    method: 'get',
    url: url,
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    params: { url: params }
  })
}

export function * getDocumentsActionEffect (params) {
  const { payload, resolve, reject } = params

  console.log('payload is ---->', payload)
  try {
    const { data } = yield call(getDocuments, payload)

    if (!isEmpty(data)) {
      resolve(data)
    } else {
      reject(data)
    }
  } catch (e) {
    reject(e)
    console.log('Error found while fetching pos')
  }
}

export function * getDocumentsActionWatcher () {
  yield takeLatest(actions.DOCUMENTS_GET_WATCHER, getDocumentsActionEffect)
}

// ######################## POSP Application Form ###############################

function getApplicationForm (params) {
  const { userId } = params;
  const url = createUrl(urls.BASE_URL + urls.pos.APPLICATION_FORM + '/'+userId)
  return axios.request({
    method: 'get',
    url: url,
    headers: {
      'Content-Type': 'application/pdf'
    }
  })
}


export function * getPOSPApplicationFormActionEffect (params) {
  const { payload, resolve, reject } = params
  try {
    const { data } = yield call(getApplicationForm, payload)
    if (!isEmpty(data)) {
      resolve(data)
    } else {
      reject(data)
    }
  } catch (e) {
    reject(e)
    console.log('Error found while fetching application form')
  }
}

export function * getPOSPApplicationFormWatcher () {
  yield takeLatest(actions.APPLICATION_FORM_WATCHER, getPOSPApplicationFormActionEffect)
}


// ######### Upload Document #####################################################

function uploadDocument (params) {
  const { posId, documentType, documentName, documentSubType, documentNumber, documentImage } = params
  const url = createUrl(urls.BASE_URL + urls.pos.BASE_URL) + '/' + posId + urls.pos.UPLOAD_DOCUMENT
  console.log(url)
  return axios.request({
    method: 'post',
    url: url,
    headers: {
      'Content-Type': 'multipart/form-data',
      // 'Content-Type': 'text/plain',
      'x-document-type': documentType || '',
      'x-document-name': documentName || '',
      'x-document-subtype': documentSubType || '',
      'x-document-number': documentNumber || ''
    },
    data: documentImage
  })
}

export function * uploadDocumentActionEffect (params) {
  const { payload, resolve, reject } = params

  console.log('payload is ---->', payload)
  try {
    const { data } = yield call(uploadDocument, payload)

    if (!isEmpty(data)) {
      resolve(data)
      yield put(fetchposDetailWatcher(payload.posId))
    } else {
      reject(data)
    }
  } catch (e) {
    console.log('Error found while fetching pos')
    reject(e)
  }
}

export function * uploadDocumentActionWatcher () {
  yield takeLatest(actions.DOCUMENT_UPLOAD_WATCHER, uploadDocumentActionEffect)
}
// ################ Update POSP Role ################################################

function updatePospRole (params) {
  const url = createUrl(urls.BASE_URL + urls.pos.BASE_URL) + '/' + params.posId + urls.pos.ROLE
  console.log(url)
  return axios.request({
    method: 'put',
    url: url,
    headers: {
      'Content-Type': 'application/json'
    },
    data: params
  })
}

export function * updatePospRoleActionEffect (params) {
  const { payload, resolve, reject } = params
  console.log('payload user id ---->', payload)
  try {
    const { data } = yield call(updatePospRole, payload)
    if (!isEmpty(data)) {
      console.log('Pos role updated successfully')
      resolve(data)
      //  yield put(posDetail(data));
    } else {
      reject(data)
      // reject(data);
    }
  } catch (e) {
    reject(e)
    console.log('Error found while fetching pos')
  }
}

export function * updatePospRoleActionWatcher () {
  yield takeLatest(actions.UPDATE_POSP_ROLE_WATCHER, updatePospRoleActionEffect)
}

// ############### Update Senior To POSP ##############################################

function updatePospSenior (params) {
  const url = createUrl(urls.BASE_URL + urls.pos.BASE_URL) + '/' + params.posId + urls.pos.SENIOR
  console.log(url)
  return axios.request({
    method: 'put',
    url: url,
    headers: {
      'Content-Type': 'application/json'
    },
    data: params
  })
}

export function * updatePospSeniorActionEffect (params) {
  const { payload, resolve, reject } = params
  console.log('payload user id ---->', payload)
  try {
    const { data } = yield call(updatePospSenior, payload)
    if (!isEmpty(data)) {
      console.log('Pos role updated successfully')
      resolve(data)
      //  yield put(posDetail(data));
    } else {
      reject(data)
      // reject(data);
    }
  } catch (e) {
    reject(e)
    console.log('Error found while fetching pos')
  }
}

export function * updatePospSeniorActionWatcher () {
  yield takeLatest(actions.UPDATE_POSP_SENIOR_WATCHER, updatePospSeniorActionEffect)
}

//##### Upload Posp Utility Watcher ######################################

function uploadPospUtility (param) {
  
  const url = createUrl(urls.BASE_URL + urls.pos.BROKER) + urls.pos.UPLOAD_POSP
  console.log(url)
  return axios.request({
    method: 'post',
    url: url,
    headers: {
      'Content-Type': 'multipart/form-data',
      // 'Content-Type': 'text/plain',

    },
    data: param
  })
}

export function * uploadPospUtilityActionEffect (params) {
  const { payload, resolve, reject } = params
  console.log('payload user id ---->', payload)
  try {
    const { data } = yield call(uploadPospUtility, payload)
    if (!isEmpty(data)) {
      console.log('Posp list uploaded successfully')
      resolve(data)
      //  yield put(posDetail(data));
    } else {
      reject(data)
      // reject(data);
    }
  } catch (e) {
    reject(e)
    console.log('Error found while fetching pos')
  }
}


export function * uploadPospUtilityActionWatcher() {
  yield takeLatest(actions.UPLOAD_POSP_UTILITY_WATCHER, uploadPospUtilityActionEffect)
}


// ############################# Search ROT users by phone ###############################

function fetchROTUser(params) {
  return axios.request({
    method: 'post',
    url: createUrl(urls.BASE_URL+'/pos/searchUserByNumberAndRole'),
    headers: {
      'Content-Type': 'application/json'
    },
    data: params
  })
}

export function* fetchROTUserByPhoneActionEffect(params) {
  const { payload } = params;
  try {
    const { data } = yield call(fetchROTUser, payload);
    if (!isEmpty(data)) {
      yield put(rotUsersList(data))
    } else {
      yield put(rotUsersList([]))
    }
  } catch (e) {
    console.log('Error found getting user roles.');
  }
}

export function* fetchROTUserByPhoneActionWatcher() {
  yield takeLatest(actions.ROT_USERS_WATCHER, fetchROTUserByPhoneActionEffect)
}

export default function* rootSagas() {
  yield all([
    fork(fetchPosListActionWatcher),
    fork(fetchPosOnboardingListActionWatcher),
    fork(fetchPosDetailActionWatcher),
    fork(updatePosEducationInfoActionWatcher),
    fork(updatePosBankInfoActionWatcher),
    fork(updatePosPersonalInfoActionWatcher),
    fork(updatePosBusinessInfoActionWatcher),
    fork(updatePosApprovalStatusActionWatcher),
    fork(updatePosCertificationInfoActionWatcher),
    fork(fetchPolicySoldListActionWatcher),
    fork(updatePosEligibiltyStatusActionWatcher),
    fork(getDocumentsActionWatcher),
    fork(uploadDocumentActionWatcher),
    fork(updatePospSeniorActionWatcher),
    fork(updatePospRoleActionWatcher),
    fork(fetchPosEarningActionWatcher),
    fork(uploadPospUtilityActionWatcher),
    fork(getPOSPApplicationFormWatcher),
    fork(fetchROTUserByPhoneActionWatcher)
  ]);
}